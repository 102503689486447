import React, { FC, useMemo, useRef, useState } from "react";

import { uniqBy } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { ReactComponent as SvgEdit } from "@assets/edit_underline.svg";
import { ReactComponent as SvgPeopleWrapper } from "@assets/people_wrapper.svg";
import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { NotificationConfirm } from "@components/ui/notification/NotificationConfirm";
import { LoaderRenderWithCondition } from "@hoc";
import { useGetContainerDimensions } from "@hooks/useGetContainerDimensions";
import { usePagination } from "@hooks/usePagination";
import { IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { SwipeList } from "@screens/day/components/ListTasks";
import { IExternalUser } from "@services/meet.service";
import { createGroup, deleteGroup, getListGroups, pagingGroups, updateGroup, userSelector } from "@store/user";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { Checkbox, HeaderModal, SearchInput, TextFont } from "@ui";
import { isTablet, showToast } from "@utils";

import { ModalGroup } from "./ModalGroup";

export interface IGroup {
  id: string;
  name: string;
  userIds?: IMeetParticipants[];
  externalUserIds?: IExternalUser[];
}

interface IProps {
  onClose: () => void;
  forParticipants?: boolean;
  onSave?: (arg: IStateUser | IExternalUser | (IMeetParticipants | IExternalUser)[]) => void;
}

export const Groups: FC<IProps> = ({ onClose, forParticipants, onSave }) => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState<string | undefined>(undefined);
  const [selectGroup, setSelectGroup] = useState<IGroup | undefined>(undefined);
  const [selectedGroups, setSelectedGroups] = useState<IGroup[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    errorMsg,
    groups: { totalPages, content: groups },
    isLoadingGroups,
  } = userSelector();

  const { attrSwipeList, search, setSearch, setPage } = usePagination({
    sendPagination: pagingGroups,
    sendList: getListGroups,
    errorMsg,
    totalPages,
    allowSearch: true,
  });

  const handleItemPress = (item: IGroup) => {
    if (forParticipants) {
      handleCheckbox(item);
    } else {
      setIsVisible(true);
      setSelectGroup(item);
    }
  };

  const handleRemove = (id: string) => {
    dispatch(deleteGroup(id));
    setIsVisible(false);
    setIsDeleting(undefined);
  };

  const handleSave = async (group: Partial<IGroup>) => {
    try {
      const body = {
        name: group.name,
        externalUserIds: group.externalUserIds?.map((i) => i.id),
        userIds: group.userIds?.map((i) => i.userId ?? i.id),
      };

      await dispatch(
        selectGroup
          ? updateGroup({
              id: selectGroup.id,
              body,
            })
          : createGroup(body),
      );

      dispatch(getListGroups({ page: 0, size: 20 }));
      setPage(0);
    } catch (error) {
      showToast("dataDidNotLoaded", "error");
    } finally {
      setIsVisible(false);
    }
  };

  const handleCheckbox = (group: IGroup) => {
    if (selectedGroups.some((i) => i.id === group.id)) {
      setSelectedGroups(selectedGroups.filter((i) => i.id !== group.id));
    } else {
      setSelectedGroups([...selectedGroups, group]);
    }
  };

  const handleSaveContainer = () => {
    if (onSave && forParticipants) {
      const result = [];
      for (const groups of selectedGroups) {
        result.push(...groups.externalUserIds, ...groups.userIds);
      }
      const filteredParticipants = result.filter((user) => user.active || !user.deleted);
      onSave(uniqBy(filteredParticipants, "id"));
    }
    onClose();
  };

  const isEdit = useMemo(() => (forParticipants ? !!selectedGroups.length : false), [forParticipants, selectedGroups.length]);

  return (
    <section style={styles.container} ref={containerRef}>
      <HeaderModal
        title={forParticipants ? "listOfGroups" : "groupsParticipants"}
        leftSide={{ onPressClose: onClose, isHideCancel: true }}
        isEdit={isEdit}
        rightSide={{ onPress: handleSaveContainer }}
        styleContainer={isTablet && !forParticipants && { backgroundColor: "#ECF0EF", paddingTop: 40 }}
      />

      <div style={{ ...styles.main, gridTemplateRows: forParticipants ? "5% 90%" : "7% 7% 80%" }}>
        <SearchInput isDrop value={search} styleWrapper={styles.searchWrapper} maxLength={100} setValue={setSearch} autoRenderDrop />

        {!forParticipants && (
          <button
            style={{ ...styles.item, justifyContent: "flex-start" }}
            onClick={() => {
              setIsVisible(true);
              setSelectGroup(undefined);
            }}
          >
            <div style={styles.iconPlus}>
              <SvgPlus width={11} height={11} fill="white" />
            </div>
            <TextFont size={16} weight="700">
              <FormattedMessage id="createGroup" />
            </TextFont>
          </button>
        )}

        <LoaderRenderWithCondition condition={isLoadingGroups} parent={containerRef.current}>
          <SwipeList
            data={groups}
            element={({ item, handleRightClick }) => (
              <button style={styles.item} onClick={() => handleItemPress(item)} onContextMenu={(e) => handleRightClick(e, item.id)}>
                <div style={{ ...styles.flexRow, gap: 14, width: forParticipants ? "70%" : "90%" }}>
                  <SvgPeopleWrapper fill={Colors.LIGHT.blue} />
                  <TextFont size={16} weight="700" style={{ ...commonStyles.oneOfLines, width: "90%" }}>
                    {item.name}
                  </TextFont>
                </div>

                {forParticipants && (
                  <div style={styles.flexRow}>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsVisible(true);
                        setSelectGroup(item);
                      }}
                    >
                      <SvgEdit />
                    </button>
                    <Checkbox
                      onChange={() => handleCheckbox(item)}
                      isChecked={selectedGroups.some((i) => i.id === item.id)}
                      style={{ marginRight: 8, marginLeft: 5 }}
                    />
                  </div>
                )}

                {!forParticipants && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDeleting(item.id);
                    }}
                  >
                    <SvgRemove fill={Colors.LIGHT.background.grey} />
                  </button>
                )}
              </button>
            )}
            buttons={() => null}
            // styles={{height: "87%"}}
            {...attrSwipeList}
          />
        </LoaderRenderWithCondition>
      </div>

      {isVisible && (
        <ModalGroup
          group={selectGroup}
          onSave={handleSave}
          onRemove={handleRemove}
          forParticipants={forParticipants}
          onClose={() => setIsVisible(false)}
        />
      )}

      {isDeleting && (
        <NotificationConfirm
          isOpen
          phraseId="deleteGroup"
          onOk={() => handleRemove(isDeleting)}
          onCancel={() => setIsDeleting(undefined)}
          phraseCancelId="cancelClose"
          phraseOkId="yes"
        />
      )}
    </section>
  );
};

const styles = {
  container: {
    height: isTablet ? "90%" : "100%",
  },
  main: {
    display: "grid",
    paddingTop: 25,
    height: "100%",
  },
  searchWrapper: {
    paddingLeft: 12,
    paddingRigh: 12,
    marginBottom: 14,
  },
  item: {
    display: "flex",
    gap: 14,
    alignItems: "center",
    borderBottom: `1px solid ${Colors.LIGHT.lighGrey}`,
    padding: "8px 10px",
    backgroundColor: "white",
    justifyContent: "space-between",
    width: "100%",
  },
  iconPlus: {
    display: "flex",
    backgroundColor: "#297952",
    borderRadius: 32,
    width: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
  },
  wrapperHiddenBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  },
  hiddenBtn: {
    display: "flex",
    "flex-direction": "column",
    gap: 6,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: 65,
    backgroundColor: Colors.LIGHT.red,
  },
  flexRow: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
};
