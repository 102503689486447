import { useEffect, useMemo } from "react";
import { useState } from "react";

import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";

import { ITask } from "@interfaces/personalGoals.interface";
import { selectYearMonth } from "@store/date";
import { selectCurrentGoal } from "@store/personalGoals";

interface IProps {
  goalId: string;
}

export const useStartData = ({ goalId }: IProps) => {
  const goal = useSelector(selectCurrentGoal(goalId));
  const yearMonth = useSelector(selectYearMonth);

  const [isEdit, setIsEdit] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [tasks, setTasks] = useState<ITask[]>(goal?.personalTasks ? goal.personalTasks : []);
  const [isAddTask, setIsAddTask] = useState(false);
  const [isCanAddTask, setIsCanAddTask] = useState(false);

  useEffect(() => {
    setIsEdit(!isEqual(tasks, goal?.personalTasks ? goal.personalTasks : []));
  }, [tasks, goal?.personalTasks, goal]);

  useEffect(() => {
    const isBeforeMonth = dayjs(yearMonth).isBefore(dayjs(), "month");
    setIsCanAddTask(!isBeforeMonth);
  }, [yearMonth]);

  return useMemo(
    () => ({
      isEdit,
      setIsEdit,
      isCancel,
      setIsCancel,
      tasks,
      setTasks,
      isAddTask,
      setIsAddTask,
      isCanAddTask,
      setIsCanAddTask,
      goal,
      yearMonth,
    }),
    [isEdit, setIsEdit, isCancel, setIsCancel, tasks, setTasks, isAddTask, setIsAddTask, isCanAddTask, setIsCanAddTask, goal, yearMonth],
  );
};
