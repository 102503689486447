import React, { FC, useEffect, useMemo, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { ReactComponent as CalendarGrey } from "@assets/calendar_dots_grey.svg";
import { ReactComponent as SvgCopy } from "@assets/copy_border.svg";
import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgSettings } from "@assets/settings.svg";
import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { ReactComponent as SvgZoom } from "@assets/zoom.svg";
import { regExpOnlyUrl, regExpUrl } from "@constants/regExp";
import { DIRECTOR_LINK } from "@constants/settings";
import { ModalRenderWithCondition } from "@hoc";
import { IMeet, IMeetingRoom } from "@interfaces/meet.interface";
import { LinkToZoom } from "@screens/profile/components/Settings/linkToZoom/LinkToZoom";
import { meetService } from "@services/meet.service";
import { useGetAvailablePlacesQuery, useGetListMeetingRoomsQuery } from "@services/meetApi";
import { useGetZoomAuthStatusQuery } from "@services/userApi";
import { selectIsDogmaUser, userSelector } from "@store/user";
import { selectUserPersonalSettings } from "@store/userSettings";
import { updatePersonalSettings } from "@store/userSettings/api";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { ArrowSvg, FloatMenu, HeaderModal, Loader, ModalUI, TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";

import { IStartData } from "../activityParticipants/ActivityParticipants";

import { MeetingRoomsBooking } from "./meetingRoomsBooking/MeetingRoomsBooking";

const eventHelperUtils = new EventHelperUtils();

interface IProps {
  onClose: () => void;
  onSave: (arg: string, index: number) => void;
  currentPlace: { title: string; index: number; id: string };
  places: string[];
  meet: IMeet;
  startData: IStartData;
  allowEdit: boolean;
  handleCreate?: (name: string, value: unknown) => void;
}

export const ModalPlacement: FC<IProps> = ({ onClose, onSave, currentPlace, places, meet, startData, allowEdit, handleCreate }) => {
  const dispatch = useDispatch();
  const { directorId } = userSelector();

  const [text, setText] = useState("");
  const [permanentLink, setPermanentLink] = useState("");
  const [directorPermanentLink, setDirectorPermanentLink] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [skipGetPlaces, setSkipGetPlaces] = useState(true);
  const [zoomConference, setZoomConference] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const [isVisibleMenu, setIsVisibleMenu] = useState(false);
  const [isVisiblePlaces, setIsVisiblePlaces] = useState(false);
  const [cityMeetingRooms, setCityMeetingRooms] = useState<IMeetingRoom[] | null>(null);
  const [selectedData, setSelectedData] = useState<IMeetingRoom | null | undefined>(undefined);

  const settings = useSelector(selectUserPersonalSettings);
  const isDogmaUser = useSelector(selectIsDogmaUser);

  const { data: availablePlaces } = useGetAvailablePlacesQuery(undefined, {
    skip: skipGetPlaces,
  });
  const { data: zoomStatus } = useGetZoomAuthStatusQuery(undefined);

  const { data: listMeetingRooms } = useGetListMeetingRoomsQuery(undefined, {
    skip: skipGetPlaces,
  });

  useEffect(() => {
    if (
      availablePlaces &&
      currentPlace &&
      currentPlace.title !== settings?.videoConferencePermanentUrl &&
      !availablePlaces?.includes(currentPlace.title) &&
      !JSON.stringify(listMeetingRooms)?.includes(currentPlace?.id)
    ) {
      setText(currentPlace.title);
    }
  }, [availablePlaces, currentPlace, settings?.videoConferencePermanentUrl, listMeetingRooms]);

  useEffect(() => {
    if (!availablePlaces && isDogmaUser) {
      setSkipGetPlaces(false);
    }
  }, [availablePlaces, isDogmaUser]);

  useEffect(() => {
    const roomsOfCity = listMeetingRooms?.filter((item) => item?.meetingRooms?.find((room) => room?.id === currentPlace?.id))[0]
      ?.meetingRooms;
    if (listMeetingRooms && roomsOfCity && currentPlace.index !== -1) {
      setTimeout(() => {
        setIsVisiblePlaces(true);
        setCityMeetingRooms(roomsOfCity ?? []);
      }, 300);
    }
  }, [currentPlace, listMeetingRooms]);

  useEffect(() => {
    if (!directorId) return;

    const directorLink = localStorage.getItem(DIRECTOR_LINK);
    setDirectorPermanentLink(directorLink ?? "");
  }, [directorId]);

  useEffect(() => {
    if (!meet.id && selectedData !== undefined) {
      startData.handleData({ ...meet, meetingRoomId: selectedData?.id ?? null, meetingRoom: selectedData });
      onClose();
    }
  }, [selectedData]);

  const handlePressSaveHeader = () => {
    if (zoomConference) return handleSave(zoomConference);
    if (text?.match(regExpUrl)) return handleSave(text?.match(regExpUrl)[0]);
    return handleSave(text);
  };

  const handleGetZoomConference = async () => {
    if (zoomConference) return;
    try {
      setIsLoading(true);
      const response = await meetService.getLinkToZoomConference({
        startTime: meet.startTime,
        endTime: meet.endTime,
        descroption: meet.description,
        name: meet.name,
      });
      setZoomConference(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = (val: string) => {
    onSave(val, currentPlace.index);
    onClose();
  };

  const handleUserLink = async () => {
    if (directorPermanentLink) {
      handleSave(directorPermanentLink);
    } else if (settings?.videoConferencePermanentUrl) {
      handleSave(settings.videoConferencePermanentUrl);
    } else {
      setIsVisible(true);
    }
  };

  const handleSavePermanentLink = () => {
    dispatch(updatePersonalSettings({ id: directorId, body: { videoConferencePermanentUrl: permanentLink } }));
    handleSave(permanentLink);
  };

  const isEdit = !!permanentLink?.match(regExpOnlyUrl) && !places?.includes(permanentLink);
  const isPermanentLink = useMemo(() => {
    if (directorPermanentLink) return places?.includes(directorPermanentLink);
    return places?.includes(settings?.videoConferencePermanentUrl ?? "");
  }, [directorPermanentLink, places, settings?.videoConferencePermanentUrl]);

  const textPermanentLinkBtn = useMemo(() => {
    if (directorId) {
      if (directorPermanentLink) return "Использовать постоянную ссылку руководителя";
      return "Использовать постоянную ссылку ассистента";
    }
    return "Использовать постоянную ссылку";
  }, [directorId, directorPermanentLink]);

  return (
    <ModalUI onClose={onClose} isVisible>
      <HeaderModal
        title="Выбрать место"
        leftSide={{ onPressClose: onClose, isHideCancel: true }}
        isEdit={(!!text && text !== currentPlace.title) || !!zoomConference}
        rightSide={{ onPress: handlePressSaveHeader, styleText: { fontWeight: "700" } }}
      />
      <section style={styles.container}>
        <div>
          <TextFont type="bold" style={styles.title}>
            <FormattedMessage id="meetPlaceIn" />
          </TextFont>

          <div>
            <input
              value={text}
              style={styles.input}
              onChange={(e) => setText(e.target.value)}
              placeholder="Место встречи"
              maxLength={255}
            />
            <TextFont size={12} style={{ marginLeft: 16 }} color={Colors.LIGHT.grey500}>
              Напишите название места или вставьте ссылку
            </TextFont>
          </div>
        </div>
        <div>
          <TextFont type="bold" style={styles.title}>
            <FormattedMessage id="meetPlaceOnline" />
          </TextFont>

          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
              {zoomStatus === "AUTHORIZED" && (
                <BtnZoom disabled={!!zoomConference} onClick={handleGetZoomConference}>
                  <BtnZoomMain>
                    <BtnZoomMainTitle>
                      <SvgZoom width={24} height={24} />
                      <TextFont size={16} weight="700">
                        <FormattedMessage id="addZoomVideoMeeting" />
                      </TextFont>
                    </BtnZoomMainTitle>
                    <RenderWithCondition condition={!zoomConference}>
                      {isLoading ? <Loader /> : <ArrowSvg type="down" width={18} height={18} />}
                    </RenderWithCondition>

                    {zoomConference && (
                      <div>
                        <BtnDotes
                          data-tooltip-id="zoom-link-menu"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsVisibleMenu(true);
                          }}
                        >
                          <SvgDotes width={18} height={18} fill={Colors.LIGHT.background.grey} />
                        </BtnDotes>
                        <FloatMenu
                          id="zoom-link-menu"
                          config={[
                            {
                              title: "copyTheLink",
                              icon: <SvgCopy />,
                              onPress: () => eventHelperUtils.copyToClipBoard("fd"),
                            },
                            {
                              title: "delete",
                              icon: <SvgDelete />,
                              onPress: () => setZoomConference(undefined),
                              styleText: { color: Colors.LIGHT.red },
                            },
                          ]}
                          onClose={() => setIsVisibleMenu(false)}
                          place="top-start"
                        />
                      </div>
                    )}
                  </BtnZoomMain>
                  {zoomConference && (
                    <a href={zoomConference}>
                      <TextFont color={Colors.LIGHT.blue}>fsdfds</TextFont>
                    </a>
                  )}
                </BtnZoom>
              )}
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
              <button
                onClick={handleUserLink}
                disabled={isPermanentLink}
                style={{
                  ...styles.btn,

                  backgroundColor: isPermanentLink ? Colors.LIGHT.grey : Colors.LIGHT.background.green,
                }}
              >
                <TextFont color={"white"} size={16} weight="700">
                  {textPermanentLinkBtn}
                </TextFont>
              </button>
              <button onClick={() => setIsVisible(true)} style={styles.settings}>
                <SvgSettings />
              </button>
            </div>
          </div>
        </div>

        <MeetingRooms>
          <TextFont type="bold" style={styles.title}>
            <FormattedMessage id="selectMeetingRoom" defaultMessage={"Подобрать переговорную"} />
          </TextFont>
          <div style={{ paddingLeft: -12, paddingRight: -12 }}>
            {listMeetingRooms &&
              listMeetingRooms?.map((item) => (
                <button
                  onClick={() => {
                    setIsVisiblePlaces(true);
                    setCityMeetingRooms(item?.meetingRooms ?? []);
                  }}
                  style={{ ...commonStyles.billet, marginTop: 5 }}
                  key={item.location}
                >
                  <LeftBlock>
                    <CalendarGrey />
                    <TextFont size={16} weight="700">{`г ${item.location}`}</TextFont>
                  </LeftBlock>
                  <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
                </button>
              ))}
          </div>
        </MeetingRooms>
      </section>

      <ModalRenderWithCondition condition={isVisiblePlaces}>
        <MeetingRoomsBooking
          close={onClose}
          allowEdit={allowEdit}
          startData={startData}
          dataMeetingRooms={cityMeetingRooms}
          titleModal="occupancy"
          titleTable="occupancyMeetingRooms"
          setIsVisiblePlaces={() => setIsVisiblePlaces(false)}
          setSelectedData={setSelectedData}
        />
      </ModalRenderWithCondition>

      <ModalUI onClose={() => setIsVisible(false)} isVisible={isVisible}>
        <HeaderModal
          title="Постоянная ссылка"
          isEdit={isEdit}
          leftSide={{ onPressClose: () => setIsVisible(false), isHideCancel: true }}
          rightSide={{ onPress: handleSavePermanentLink }}
        />
        <div style={{ padding: "20px 12px" }}>
          <LinkToZoom link={permanentLink} setLink={setPermanentLink} />
        </div>
      </ModalUI>
    </ModalUI>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    marginTop: 15,
    paddingLeft: 12,
    paddingRight: 12,
    gap: 30,
    "overflow-y": "auto",
  },
  title: {
    fontWeight: "700",
    fontSize: 18,
    marginBottom: 4,
  },
  input: {
    padding: 16,
    backgroundColor: "white",
    borderRadius: 10,
    width: "90%",
  },
  place: {
    borderBottom: "1px solid #E3E9E7",
    padding: 12,
  },

  btn: {
    padding: "12px 16px",
    borderRadius: 10,
  },
  settings: {
    padding: 10,
    backgroundColor: Colors.LIGHT.lighGrey,
    borderRadius: 10,
  },
};

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;
const MeetingRooms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;
const BtnDotes = styled.button`
  display: flex;
  height: 30px;
  justify-content: flex-end;
  padding-top: 5px;
`;

const BtnZoomMainTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BtnZoomMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BtnZoom = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${Colors.LIGHT.white};
  padding: 16px 12px;
  gap: 10px;
`;
