import { Client } from "@stomp/stompjs";
import addNotification from "react-push-notification";

import { updateTokens } from "@api/httpClient";
import { ACCESS_TOKEN } from "@constants/settings";
import { api, StoreTagTypes } from "@services/api";
import emitter from "@services/emitter";
import { getUnreadCountMessages } from "@store/user";
import { updateScreens } from "@utils/updateScreens";

import { store } from "./store";
import { secureStore } from "./utils";

const MAX_COUNT_TO_RECONNECT_TO_SOCKET = 1;

const getAuthHeader = () => {
  try {
    return `Bearer ${secureStore.getValue(ACCESS_TOKEN)}`;
  } catch (error) {}
};

const decodeJwtToken = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return null; // Handle invalid token format
  }
};

let currentUserID = "";
let clickedByCloseButton = false;

let countOfTryConnectToSocket = 0;

window.addEventListener("online", () => {
  emitter.emit("backOnline");
});

const subscribeUser = (client: Client) => {
  client.subscribe(`/topic/${currentUserID}/notifications`, (message) => {
    const data = JSON.parse(message.body);

    if (data?.link?.endsWith("revoke")) {
      emitter.emit("updateMeetings");
      store.dispatch(
        api.util.invalidateTags([
          { type: StoreTagTypes.Meet, id: "LIST" },
          { type: StoreTagTypes.Meet, id: "UNANSWERED_COUNT" },
        ]),
      );
      return;
    }

    const isTaskUpdate = data?.link.includes("refresh/tasks");
    if (isTaskUpdate) {
      emitter.emit("updateTasks");
      return;
    }

    const isMeetingUpdate = data?.link.includes("refresh/meetings");
    if (isMeetingUpdate) {
      emitter.emit("updateMeetings");
      store.dispatch(
        api.util.invalidateTags([
          { type: StoreTagTypes.Meet, id: "LIST" },
          { type: StoreTagTypes.Meet, id: "UNANSWERED_COUNT" },
        ]),
      );
      return;
    }

    const notificationData = {
      title: data.title,
      duration: 20000,
      message: data.message,
      theme: "darkblue" as any,
      onClick: () => {
        if (!clickedByCloseButton) {
          window.location.href = url.toString();
        }
        clickedByCloseButton = false;
      },
    };

    if (!data.link) {
      addNotification({
        ...notificationData,
        closeButton: <div onClick={() => (clickedByCloseButton = true)}>Закрыть</div>,
        native: false,
      });

      addNotification({
        ...notificationData,
        native: true,
      });

      store.dispatch(getUnreadCountMessages());
      return;
    }

    const [linkType, objectId] = data.link
      .slice(data.link.indexOf("//") + 2)
      .split("/")
      .slice(-2);

    const url = new URL(`https://${window.location.host}`);
    url.searchParams.append(linkType, objectId);

    addNotification({
      ...notificationData,
      closeButton: <div onClick={() => (clickedByCloseButton = true)}>Закрыть</div>,
      native: false,
    });

    addNotification({
      ...notificationData,
      native: true,
    });

    store.dispatch(getUnreadCountMessages());
    updateScreens(store, linkType);
  });
};

window.addEventListener("load", async () => {
  const client = new Client({
    brokerURL: process.env.REACT_APP_BASE_SOCKET_PUSH_URL,
    reconnectDelay: 5000,
    connectHeaders: {
      Authorization: getAuthHeader(),
    },
    onConnect: () => {
      const tryToSubscribeUser = () =>
        setTimeout(() => {
          if (!currentUserID) {
            tryToSubscribeUser();
            return;
          }
          subscribeUser(client);
        }, 1000);

      tryToSubscribeUser();
    },
    onStompError: (frame) => {
      console.error(`Broker reported error: ${frame.headers.message}`);
      console.error(`Additional details: ${frame.body}`);
    },
    onWebSocketError: (e) => {
      console.error("WebSocket encountered an error:", e);
    },
    onWebSocketClose: async (e) => {
      if (countOfTryConnectToSocket >= MAX_COUNT_TO_RECONNECT_TO_SOCKET) {
        countOfTryConnectToSocket = 0;
        client.deactivate();
        return;
      }

      try {
        countOfTryConnectToSocket += 1;
        // countRefreshToken += 1;
        const header = getAuthHeader();
        if (header) {
          // Decode the token to check the expiration time
          const token = header.split(" ")[1]; // Assuming the header is in the format 'Bearer <token>'
          const decodedToken = decodeJwtToken(token); // Implement this function to decode your token
          const tokenExpirationTime = decodedToken.exp * 1000; // Convert to milliseconds
          const currentTime = Date.now();
          const remainingTime = tokenExpirationTime - currentTime;

          if (remainingTime < 10000) {
            // If less than 10 seconds remaining
            await updateTokens();
          }

          await client.deactivate();

          client.connectHeaders["Authorization"] = header;

          setTimeout(() => {
            client.activate();
          }, 10000);

          countOfTryConnectToSocket = 0;
        }
      } catch (error) {}
    },
  });

  try {
    if (secureStore.getValue(ACCESS_TOKEN)) {
      client.activate();
    }
  } catch {}

  emitter.addListener("get_user_id", (userId) => {
    currentUserID = userId;
  });

  emitter.addListener("login", () => {
    client.activate();
  });

  emitter.addListener("logout", () => {
    if (client && client.connected) {
      client.deactivate();
    }
  });

  emitter.addListener("backOnline", () => {
    client.deactivate().then(() => {
      client.activate();
    });
  });
});
