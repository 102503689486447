import React, { FC, useMemo, useCallback } from "react";

import dayjs from "dayjs";
import { isNull } from "lodash";
import { FormattedMessage } from "react-intl";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { ReactComponent as SvgMessage } from "@assets/message.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import { IMeetParticipants, TTypeParticipants, IMeet } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components/LetterAvatar";
import { IExternalUser } from "@services/meet.service";
import { useSetParticipantStatusCommentAsViewedMutation } from "@services/meetApi";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { userSelector } from "@store/user";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { AssistantToast, TextFont } from "@ui";
import globalState from "@utils/globalState";
import { toFormatDate } from "@utils/toFormatTime";
import { isInnerMeetParticipant } from "@utils/userTypeChecks";

import { ReactComponent as SvgAttend } from "../assets/attend.svg";
import { ReactComponent as SvgMayBe } from "../assets/maybe.svg";
import { ReactComponent as SvgNotAnswer } from "../assets/not_answer.svg";
import { ReactComponent as SvgNotAttend } from "../assets/not_attend.svg";
import { ReactComponent as SvgOwner } from "../assets/owner.svg";

const iconStatus: Record<TTypeParticipants, JSX.Element> = {
  OWNER: <SvgOwner />,
  ATTENDER: <SvgAttend />,
  NON_ATTENDER: <SvgNotAttend />,
  MAYBE_ATTENDER: <SvgMayBe />,
};

interface IProps {
  user: IMeetParticipants | IExternalUser;
  isExternal?: boolean;
  isSave: boolean;
  handleDeleteItem: (id: string, isExternal: boolean) => void;
  getUser: (userId: string) => IMeetParticipants | IStateUser | undefined;
  setSelectUser: React.Dispatch<React.SetStateAction<IStateUser | undefined>>;
  assistant?: Partial<IStateUser> | null;
  isUserHasEditPermissions?: boolean;
  isModalList?: boolean;
  meet: IMeet;
  participantsAttendance: IParticipantAttendance[] | null;
}

export const ParticipantsListItem: FC<IProps> = ({
  user,
  isSave,
  isExternal = false,
  getUser,
  handleDeleteItem,
  setSelectUser,
  assistant,
  isUserHasEditPermissions = false,
  isModalList = false,
  meet,
  participantsAttendance,
}) => {
  const [setParticipantStatusCommentAsViewed] = useSetParticipantStatusCommentAsViewedMutation();
  const participant = getUser(user.userId) as IStateUser;
  const isInner = isInnerMeetParticipant(user);

  const { currentUser, directorId } = userSelector();

  const isItemDeleteAllowed = useMemo(() => {
    const itemId = user?.user?.id ?? user.id;
    const isMatchedIds = directorId ? true : currentUser?.id !== itemId;

    return isInner && isUserHasEditPermissions && user.status !== "OWNER" && isMatchedIds;
  }, [user, isUserHasEditPermissions, currentUser]);

  const borderColorStatus = useCallback(
    (item: IMeetParticipants) => {
      const busyStatus = getUser(item.userId)?.busy;

      if (isNull(busyStatus)) {
        return Colors.DARK.grey;
      }

      if (!isSave) {
        const attendance = participantsAttendance?.filter((i) => i.userId === item.userId)[0];
        return attendance?.meetings.length ? globalState.colorSchema.red : globalState.colorSchema.green;
      }

      return getUser(item.userId)?.busy ? globalState.colorSchema.red : globalState.colorSchema.green;
    },
    [isSave, participantsAttendance],
  );

  const clickHandler = (uid: string) => {
    if (!isExternal) {
      setSelectUser(getUser(uid) as IStateUser | undefined);
    }
  };

  const handleHoverComment = (val: boolean) => {
    if (val) {
      if (!(user as IMeetParticipants).statusCommentIsViewed) {
        setParticipantStatusCommentAsViewed({
          id: meet.id,
          date: toFormatDate(dayjs(meet.startTime)),
          userId: user.userId,
          repeat: !!meet.repeat,
        });
      }
    }
  };

  const sizes = {
    icon: isModalList ? 32 : 38,
    button: isModalList ? 50 : 58,
  };

  return (
    <>
      <button
        key={user.userId}
        style={{ ...styles.item, height: sizes.button, ...(isModalList && { borderBottom: `1px solid ${Colors.LIGHT.lighGrey}` }) }}
        onClick={() => clickHandler(user.userId)}
      >
        <div style={{ display: "flex", width: "93%", height: "100%" }}>
          <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
            <RenderWithCondition condition={isExternal}>
              <LetterAvatar
                userName={(user as IExternalUser).firstName}
                lastName={(user as IExternalUser)?.lastName}
                style={{ backgroundColor: Colors.DARK.asphalt }}
                textStyle={{ color: globalState.colorSchema.white }}
              />
            </RenderWithCondition>
            <RenderWithCondition condition={!isExternal}>
              <>
                <UserAvatar
                  avatarFileId={participant?.avatarFileId ?? ""}
                  style={{
                    width: sizes.icon,
                    height: sizes.icon,
                    borderRadius: sizes.icon,
                    marginRight: 8,
                    ...(!(user as IMeetParticipants).status && { border: `2px solid ${borderColorStatus(user as IMeetParticipants)}` }),
                  }}
                />
                <div style={{ position: "absolute", right: 5, bottom: 0 }}>
                  {iconStatus[(user as IMeetParticipants).status] ?? <SvgNotAnswer />}
                </div>
              </>
            </RenderWithCondition>
          </div>
          <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <TextFont
              type="bold"
              size={16}
              weight="700"
              style={{ marginBottom: 0, textDecorationLine: user.deleted ? "line-through" : "none" }}
            >
              {isExternal
                ? `${(user as IExternalUser)?.firstName} ${(user as IExternalUser)?.lastName}`
                : `${participant?.lastName ?? ""} ${participant?.firstName ?? ""}`}
            </TextFont>
            <TextFont size={14} style={commonStyles.oneOfLines} color={globalState.colorSchema.placeholder.main}>
              {isExternal ? (user as IExternalUser)?.email : participant?.workPosition ?? ""}
            </TextFont>
          </div>
        </div>
        <div style={styles.remove}>
          {isInner && user.status !== "OWNER" && user.statusComment && meet.createdByCurrentUser && (
            <button data-tooltip-id={user.statusComment && `tooltip-comment_${user.id}`}>
              <SvgMessage
                fill={(user as IMeetParticipants).statusCommentIsViewed ? Colors.LIGHT.lighGrey : Colors.LIGHT.background.green}
              />
            </button>
          )}
          <RenderWithCondition condition={isItemDeleteAllowed}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteItem(isExternal ? user.id : user.userId, isExternal);
              }}
            >
              <SvgRemove width={12} height={12} fill={globalState.colorSchema.background.grey} />
            </button>
          </RenderWithCondition>
          <RenderWithCondition condition={isInner && assistant && user.status === "OWNER"}>
            <div style={{ paddingTop: 8, paddingRight: 6 }}>
              <AssistantToast assistant={assistant} />
            </div>
          </RenderWithCondition>
        </div>
      </button>

      {isInner && user.statusComment && (
        <ReactTooltip id={`tooltip-comment_${user.id}`} style={styles.toast} place="bottom-start" setIsOpen={handleHoverComment}>
          <div style={styles.wrapperComment}>
            <TextFont size={16} weight="700" type="bold">
              <FormattedMessage id="comment" />
            </TextFont>
            <TextFont weight="400" size={16}>
              {user.statusComment}
            </TextFont>
          </div>
        </ReactTooltip>
      )}
    </>
  );
};

const styles = {
  item: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "95% 5%",
    paddingLeft: 8,
    borderRadius: 10,
    backgroundColor: globalState.colorSchema.white,
  },
  remove: {
    width: "7%",
    display: "flex",
    gap: 5,
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 20,
    height: "100%",
  },
  wrapperComment: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 17,
    paddingRight: 17,
    gap: 8,
  },
  toast: {
    backgroundColor: "white",
    borderRadius: 6,
    padding: 10,
    boxShadow: " 0px 2px 6px 0px #00000017",
    zIndex: 999,
    width: 232,
  },
};
