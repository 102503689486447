// import { isAndroid } from './isAndroid';
// import Clipboard from '@react-native-clipboard/clipboard';
import { showToast } from "./toast";
import { intl } from "./translate";

export default class EventHelperUtils {
  private timeout = 1000;
  // eslint-disable-next-line @typescript-eslint/ban-types
  debounce(callback: Function, delay: number) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(callback, Number(delay));
  }

  copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text);
    showToast("textCopied");
  };
}
