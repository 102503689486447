import React, { FC, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { isEmpty, isEqual } from "lodash";
import { useIntl } from "react-intl";

import { ReactComponent as SvgDelete } from "@assets/delete.svg";
import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { UserAvatar } from "@components/shared/userAvatar/UserAvatar";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { InterfaceUser } from "@interfaces/index";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { SwipeList } from "@screens/day/components/ListTasks";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { ParticipantsList } from "@screens/day/components/timetable/main/modal/components/participants/participantsList/ParticipantsList";
import { IExternalUser } from "@services/meet.service";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { Billet, HeaderModal, ModalUI, TextFont } from "@ui";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { showToast } from "@utils/toast";
import { toFormatISO } from "@utils/toFormatTime";
import { isExternalUser, isInnerMeetParticipant } from "@utils/userTypeChecks";

import { IGroup } from "./Groups";

interface IProps {
  onClose: () => void;
  group?: IGroup;
  onRemove: (id: string) => void;
  onSave: (arg: Partial<IGroup>) => void;
  forParticipants?: boolean;
  joinData?: Partial<IGroup>;
}

export const ModalGroup: FC<IProps> = ({ onClose, group, onRemove, onSave, forParticipants, joinData }) => {
  const [data, setData] = useState<Partial<IGroup>>({ name: "", userIds: [], externalUserIds: [], ...joinData });
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleDelete, setIsVisibleDelete] = useState(false);

  useEffect(() => {
    if (group) {
      setData(group);
    }
  }, [group]);

  const handleData = (name: keyof IGroup, value: string | IMeetParticipants) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleAddUser = (users: (IStateUser | IExternalUser)[]) => {
    if (!users) return;

    const externalUsers = [];
    const innerUsers: IMeetParticipants[] = [];

    for (const user of users) {
      const isInner = isInnerMeetParticipant(user);

      if (isInner) {
        innerUsers.push({
          user: { ...user },
          userId: user?.user?.id ?? user.id,
          isExternalUser: false,
          meetingStatus: null,
        } as unknown as IMeetParticipants);
      } else {
        externalUsers.push(user as IExternalUser);
      }
    }

    setData({
      ...data,
      userIds: innerUsers,
      externalUserIds: externalUsers as IExternalUser[],
    });
  };

  const handleRemove = () => {
    if (!group) return;

    onRemove(group.id);
  };

  const handleRemoveUser = (userId: string) => {
    setData((prev) => ({
      ...prev,
      userIds: (prev.userIds ?? []).filter((i) => i.userId !== userId && i.id !== userId),
      externalUserIds: (prev.externalUserIds ?? []).filter((i) => i.id !== userId),
    }));
  };

  const handleSave = () => {
    if (!data.name.trim()) {
      return showToast("nameOfTheGroup");
    }
    onSave(data);
  };

  const isEdit = useMemo(() => (group ? !isEqual(data, group) : true), [data, group]);

  const renderItem = ({ item: user, handleRightClick }) => {
    const userItem = (user.user ? user.user : user) as IStateUser;
    const isInner = isInnerMeetParticipant(user);
    const isExternal = isExternalUser(user);

    return (
      <button key={user.userId} style={styles.item} onContextMenu={(e) => handleRightClick(e, user.id)}>
        <div style={{ position: "relative", alignSelf: "flex-start" }}>
          <RenderWithCondition condition={isInner}>
            <>
              <UserAvatar
                avatarFileId={userItem?.avatarFileId ?? null}
                style={{ width: 45, height: 45, borderRadius: 45, marginRight: 8 }}
              />
            </>
          </RenderWithCondition>

          <RenderWithCondition condition={isExternal}>
            <LetterAvatar
              userName={isExternal ? user.firstName && user?.firstName[0] : ""}
              lastName={isExternal ? user?.lastName && user?.lastName[0] : ""}
              style={{ backgroundColor: Colors.DARK.asphalt }}
              textStyle={{ color: Colors.LIGHT.white }}
            />
          </RenderWithCondition>
        </div>
        <div style={{ width: "80%" }}>
          <TextFont
            type="bold"
            size={16}
            weight="700"
            style={{ ...commonStyles.oneOfLines, ...(user?.deleted && styles.strikeThroughText) }}
          >
            {isExternal ? `${user?.firstName} ${user?.lastName}` : `${userItem?.lastName ?? ""} ${userItem?.firstName ?? ""}`}
          </TextFont>
          <TextFont size={14} color={Colors.LIGHT.placeholder.main} style={commonStyles.oneOfLines}>
            {isExternal ? user?.email : userItem?.workPosition ?? ""}
          </TextFont>
        </div>

        <button onClick={() => handleRemoveUser(user.id ?? user.userId)}>
          <SvgRemove fill={Colors.LIGHT.background.grey} />
        </button>
      </button>
    );
  };

  return (
    <ModalUI isVisible onClose={onClose}>
      <HeaderModal
        title={group ? "editGroup" : "newGroup"}
        leftSide={{ onPressClose: onClose, isHideCancel: true }}
        isEdit={!!isEdit}
        rightSide={{ onPress: handleSave }}
      />

      <section style={styles.container}>
        <div style={styles.wrapperBlock}>
          <TextFont size={18} weight="700" type="bold">
            Название группы
          </TextFont>
          <input
            value={data.name ?? ""}
            onChange={(e) => handleData("name", e.target.value)}
            style={styles.searchInput}
            placeholder="Название группы"
            maxLength={100}
          />
        </div>

        <div>
          <div style={styles.participants}>
            <TextFont size={18} weight="700" type="bold">
              {useIntl().formatMessage({ id: "groupParticipant" })}
            </TextFont>
            <button onClick={() => setIsVisible(true)}>
              <SvgPlus width={16} height={16} fill={Colors.LIGHT.background.grey} />
            </button>
          </div>

          {!isEmpty(data.userIds) || !isEmpty(data.externalUserIds) ? (
            <SwipeList
              data={[...(data.userIds ?? []), ...(data.externalUserIds ?? [])]}
              element={renderItem}
              buttons={() => null}
              styles={{ marginTop: 13, marginLeft: -12, marginRight: -12 }}
            />
          ) : (
            <Billet
              textId="addParticipants"
              additionalText="+ "
              onPress={() => setIsVisible(true)}
              styleText={{ color: Colors.LIGHT.text.accent, fontWeight: "400" }}
            />
          )}
        </div>

        {group && !forParticipants && (
          <button style={{ display: "flex", gap: 5, alignItems: "center" }} onClick={() => setIsVisibleDelete(true)}>
            <SvgDelete />
            <TextFont size={18} color={Colors.LIGHT.red}>
              Удалить группу
            </TextFont>
          </button>
        )}
      </section>

      <ModalRenderWithCondition condition={isVisible}>
        <ParticipantsList
          onClose={() => setIsVisible(false)}
          meet={
            {
              ...data,
              participants: data.userIds ?? [],
              externalUsers: data.externalUserIds ?? [],
              startTime: toFormatISO(dayjs().hour(0)),
              endTime: toFormatISO(dayjs().hour(23)),
            } as unknown as IMeet
          }
          onSave={handleAddUser}
          isSelectFromMeeting={false}
          isSelectGroup={false}
          isShowStatusBorder={false}
          isMeet={false}
        />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isVisibleDelete}>
        <NotificationConfirm
          isOpen
          phraseId="deleteGroup"
          onOk={handleRemove}
          onCancel={() => setIsVisibleDelete(false)}
          phraseCancelId="cancelClose"
          phraseOkId="yes"
        />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const styles = {
  container: {
    display: "flex",
    "flex-direction": "column",
    padding: "16px 12px",
    gap: 30,
    "overflow-y": "auto",
    height: "90%",
  },
  participants: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  wrapperBlock: {
    display: "flex",
    "flex-direction": "column",
    gap: 8,
  },
  searchInput: {
    borderRadius: 10,
    backgroundColor: "white",
    padding: 16,
    color: "black",
    width: "92%",
    fontSize: 18,
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    backgroundColor: Colors.LIGHT.white,
    borderBottom: `1px solid ${Colors.LIGHT.lighGrey}`,
    width: "100%",
  },
  wrapperHiddenBtn: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  },
  hiddenBtn: {
    display: "flex",
    "flex-direction": "column",
    gap: 6,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: 65,
    backgroundColor: Colors.LIGHT.red,
  },
  strikeThroughText: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
  },
};
