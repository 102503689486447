import React, { FC, memo, useMemo, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { ReactComponent as SvgCopy } from "@assets/copy_double.svg";
import { ReactComponent as SvgEdit } from "@assets/edit_underline.svg";
import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { regExpUrl } from "@constants/regExp";
import { RenderWithCondition } from "@hoc";
import { IMeet } from "@interfaces/meet.interface";
import { SwipeList } from "@screens/day/components/ListTasks";
import { selectTheme } from "@store/theme";
import { commonStyles } from "@styles/styles";
import { ArrowSvg, TaskActions, TextFont } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";
import globalState from "@utils/globalState";
import { showToast } from "@utils/toast";

import { modalStyles } from "../../../../../ListTasks/Modal/styles";
import { IStartData } from "../activityParticipants/ActivityParticipants";

import { ModalPlacement } from "./ModalPlacement";

interface IProps {
  places: string[];
  handleData: (name: string, value: unknown) => void;
  allowEdit?: boolean;
  startData: IStartData;
  isButtonAdd?: boolean;
  meet: IMeet;
}

export const Placement: FC<IProps> = ({ places = [], handleData, allowEdit, startData, isButtonAdd = true, meet }) => {
  const [currentPlace, setCurrentPlace] = useState<{ title: string; index: number; id: string } | undefined>(undefined);

  const theme = selectTheme("extra");

  const handleRemove = (id: string) => {
    if (places.includes(id)) {
      handleData(
        "places",
        [...places].filter((i) => i !== id),
      );
    } else {
      handleData("meetingRoomId", null);
    }
  };

  const handleAdd = (place: string, index: number) => {
    if (index === -1) {
      handleData("places", [...places, place]);
    } else {
      const temp = [...places];
      temp.splice(index, 1, place);
      handleData("places", temp);
    }
  };

  const buttons = ({ item, pos, isVisible: isVisibleActions }) =>
    isVisibleActions && <TaskActions pos={pos} configuration={{ delete: { onClick: () => handleRemove(item?.id) } }} />;

  const msgMeetOnline = useIntl().formatMessage({ id: "meetPlaceOnline" });
  const msgMeetPlaceZoom = useIntl().formatMessage({ id: "meetPlaceZoom" });
  const msgMeetPlaceGoogle = useIntl().formatMessage({ id: "meetPlaceGoogle" });
  const msgMeetPlaceTelemost = useIntl().formatMessage({ id: "meetPlaceTelemost" });
  const msgMeetPlaceSkype = useIntl().formatMessage({ id: "meetPlaceSkype" });

  const getMeetPlaceName = (meetPlace: string) => {
    const isLink = meetPlace?.match(regExpUrl);

    if (!isLink) {
      return meetPlace;
    }

    const domain = meetPlace?.match(/https?:\/\/(?<domain>[^:/?,]*)/)![0];

    if (domain?.includes("zoom")) {
      return msgMeetPlaceZoom;
    }
    if (meetPlace?.includes("meet.google")) {
      return msgMeetPlaceGoogle;
    }
    if (meetPlace?.includes("telemost")) {
      return msgMeetPlaceTelemost;
    }
    if (meetPlace?.includes("join.skype")) {
      return msgMeetPlaceSkype;
    }

    return msgMeetOnline;
  };

  const totalListRooms = useMemo(() => {
    const virtualRooms = places?.map((i) => ({ id: i, title: getMeetPlaceName(i) })) ?? [];
    if (meet.meetingRoom) {
      return [...virtualRooms, { id: meet.meetingRoom?.id, title: meet.meetingRoom?.name }];
    }
    return virtualRooms;
  }, [places, meet]);

  return (
    <section>
      <div style={styles.header}>
        <TextFont type="bold" size={18} weight="700">
          <FormattedMessage id="meetPlace" />
        </TextFont>
        {allowEdit && (
          <button disabled={totalListRooms?.length > 2} onClick={() => setCurrentPlace({ title: "", index: -1, id: meet.meetingRoom?.id })}>
            <SvgPlus
              width={16}
              height={16}
              fill={totalListRooms?.length > 2 ? globalState.colorSchema.lighGrey : globalState.colorSchema.background.grey}
            />
          </button>
        )}
      </div>

      {!!totalListRooms.length && (
        <div style={{ marginTop: 13 }}>
          <SwipeList
            data={totalListRooms}
            element={(data: { item; index; handleRightClick }) => {
              const isLink = data.item.title?.match(regExpUrl);

              return (
                <button onContextMenu={(e) => allowEdit && data.handleRightClick(e, data.item?.id)} style={styles.item}>
                  <div style={{ display: "flex", gap: 24 }}>
                    <TextFont type="bold" weight="700" size={16} style={{ flex: 0.8, ...commonStyles.oneOfLines }}>
                      {getMeetPlaceName(data.item.title)}
                    </TextFont>
                    <div style={styles.itemButtons}>
                      {allowEdit && (
                        <button onClick={() => setCurrentPlace({ index: data.index, title: data.item.title, id: data?.item?.id })}>
                          <SvgEdit />
                        </button>
                      )}
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(data.item.title);
                          showToast("textCopied");
                        }}
                      >
                        <SvgCopy />
                      </button>
                    </div>
                  </div>
                  {isLink && (
                    <div style={{ display: "flex", gap: 24 }}>
                      <a
                        target="_blank"
                        style={{
                          flex: 0.8,
                          fontSize: 16,
                          color: globalState.colorSchema.text.accent,
                          textAlign: "left",
                          ...commonStyles.oneOfLines,
                        }}
                        href={data.item.title}
                      >
                        {data.item.title}
                      </a>
                      <div style={{ flex: 0.2 }} />
                    </div>
                  )}
                </button>
              );
            }}
            style={{ marginHorizontal: -13 }}
            buttons={buttons}
          />
        </div>
      )}

      <RenderWithCondition condition={totalListRooms?.length < 3 && isButtonAdd}>
        <button style={commonStyles.billet} onClick={() => setCurrentPlace({ title: "", index: -1, id: meet?.meetingRoom?.id })}>
          <TextFont style={{ ...modalStyles.blockTurnText, ...{ color: theme.text.accent } }}>
            {"+ "}
            <FormattedMessage id="addMeetPlace" defaultMessage={"Добавить место встречи / ссылку"} />
          </TextFont>
          <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
        </button>
      </RenderWithCondition>

      {currentPlace !== undefined && (
        <ModalPlacement
          currentPlace={currentPlace}
          places={places}
          meet={meet}
          onClose={() => setCurrentPlace(undefined)}
          onSave={handleAdd}
          allowEdit={allowEdit}
          startData={startData}
        />
      )}
    </section>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 6,
  },
  item: {
    backgroundColor: "white",
    padding: 12,
    borderBottom: "1px solid #E3E9E7",
    outline: "none",
    width: "100%",
  },
  itemButtons: {
    display: "flex",
    flex: 0.2,
    gap: 18,
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
