export type TFileExtension =
  | "png"
  | "docx"
  | "jpeg"
  | "jpg"
  | "pdf"
  | "txt"
  | "pptx"
  | "xls"
  | "doc"
  | "gif"
  | "zip"
  | "rar"
  | "html"
  | "xlsx";

const fileTypeToBlob: Record<TFileExtension, string> = {
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  png: "image/png",
  gif: "image/gif",
  html: "text/html",
  pdf: "application/pdf",
  zip: "application/zip",
  rar: "application/rar",
  docx: "application/msword",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.ms-excel",
  pptx: "application/vnd.ms-powerpoint",
  txt: "application/vnd.oasis.opendocument.text",
  doc: "application/vnd.oasis.opendocument.text",
};

export const convertFileTypeToBlob = (extension: TFileExtension) => fileTypeToBlob[extension];
