import { memo, useCallback, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";
import { isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { ReactComponent as CalendarGray } from "@assets/calendar-gray.svg";
import { ReactComponent as SvgDotes } from "@assets/three_dotes.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { useTask } from "@hooks/useTask";
import { ITask, WorkDay } from "@interfaces/businessGoals.interface";
import { IFile } from "@interfaces/files.interfaces";
import { InterfaceUser } from "@interfaces/index";
import { changeFirstAndLastLetter } from "@screens/day/components/header/utils";
import { ModalControlDelegate } from "@screens/day/components/ListTasks/components/modalControlDelegate";
import { TaskMeetings } from "@screens/day/components/ListTasks/Modal/components/taskMeetings/TaskMeeting";
import { PlanningWork } from "@shared";
import { FileAttachments } from "@shared/fileAttachments/FIleAttachments";
import { IRemoveFilesFromServer } from "@shared/fileAttachments/useFileAttachments";
import { selectEventsMeta } from "@store/attachedMeetingTasks/selectors";
import { create, delegate, selectBusinessTasks, takeMe, update } from "@store/businessTask";
import { createPersonal } from "@store/personalTasks/api";
import { buildPersonalTaskDataBody } from "@store/personalTasks/utils";
import { selectCurrentDate } from "@store/screenDay";
import { getList, selectStoreTag } from "@store/tag";
import { clearTagId } from "@store/tag/slice";
import { selectTheme } from "@store/theme";
import { selectIsModeAssistant, selectUserPermissoins } from "@store/user";
import { TextFont, HeaderModal } from "@ui";
import { Notification } from "@ui/notification/Notification";
import { calendar, diff, toFormatDate } from "@utils";
import globalState from "@utils/globalState";
import { myTracker } from "@utils/myTracker";
import { shouldShowWorkdayReminder } from "@utils/shouldShowWorkdayReminder";
import { isFulfilledStatus } from "@utils/statusDispatch";

import { tempTask, tempWorkDay, WorkDays } from "../../index";
import { AddWorkDaysReminder } from "../addWorkDaysReminder";
import { ButtonDelegate } from "../delegate";
import { Delegate } from "../delegate/Delegate";

import { Deadline, Goal, Inputs, Project, Tag, Priority, Actions } from "./components";
import { PersonalTaskCheckbox } from "./components/personalTaskCheckBox/PersonalTaskCheckbox";
import { styles } from "./styles";

import "react-toggle/style.css";

interface IViewNotSave {
  handleClose: () => void;
  task: ITask | null;
  isCancel: boolean;
  setIsEdit: (arg: boolean) => void;
  isEdit: boolean;
  setIsCancel: (arg: boolean) => void;
  setIsSave: (arg: boolean) => void;
  joinData?: Record<keyof ITask, ITask[keyof ITask]>;
  isPersonalTask: boolean;
  disablePersonalTasks: boolean;
  uploadedFiles: IFile[];
  allFiles: IFile[];
  openFilePicker: (params: { closeOverModal?: () => void }) => void;
  deleteFile: (id: string) => void;
  handleFileDownload: (id: string, fileName: string) => void;
  removeUploadedFilesFromServer: (params?: IRemoveFilesFromServer) => void;
}

export const ViewNotSave = memo(
  ({
    handleClose,
    task,
    setIsEdit,
    joinData,
    isEdit,
    setIsCancel,
    setIsSave,
    isPersonalTask = false,
    disablePersonalTasks = false,
    uploadedFiles,
    allFiles,
    openFilePicker,
    deleteFile,
    handleFileDownload,
    removeUploadedFilesFromServer,
  }: IViewNotSave) => {
    const dispatch = useDispatch();
    const screen = useLocation().pathname;
    const propsUseTask = useTask({
      initialText: task?.name ?? "",
    });

    const dateNow = useSelector(selectCurrentDate);
    const theme = selectTheme("extra");

    const isScreenDay = useMemo(() => Boolean(screen === "/") && !task, [screen, task]);

    const [data, setData] = useState<ITask>(
      task ?? {
        ...tempTask,
        startDate: screen === "/" ? toFormatDate(isScreenDay ? dateNow : dayjs()) : null,
        workDays: task?.timeEnabled ? [] : [{ date: toFormatDate(dateNow), ...tempWorkDay }],
        ...joinData,
      },
    );

    const [isVisibleAddDay, setIsVisibleAddDay] = useState(false);
    const [isVisibleCalendar, setisVisibleCalendar] = useState(false);
    const [isVisibleActions, setisVisibleActions] = useState(false);
    const [isModalControlDelegateVisible, setIsModalControlDelegateVisible] = useState(false);
    const [isNeedToDelayTaskDelegation, setIsNeedToDelayTaskDelegation] = useState(false);
    const [memoizedProjectName, setMemoizedProjectName] = useState<string | null>(null);
    const [isMarkedAsPersonalTask, markAsPersonalTask] = useState(false);
    const [isAddWorkDayReminderVisible, setIsWorkDayReminderVisible] = useState(false);
    const { isLoadingTask } = useSelector(selectBusinessTasks);
    const { tags } = useSelector(selectStoreTag);
    const permissons = useSelector(selectUserPermissoins);
    const isModeAssistant = useSelector(selectIsModeAssistant);
    const attachEventsMeta = useSelector(selectEventsMeta);
    const tag = data?.tagId === undefined ? data.tag?.id : data?.tagId;

    const isTask = useMemo(() => {
      if (!data["type"]) return true;
      if (data.type === "TASK") return true;

      return false;
    }, []);

    const isTaskDelegateAble = useMemo(() => {
      if (isTask && !isMarkedAsPersonalTask) return true;
      if (isTask && isMarkedAsPersonalTask) return false;

      return false;
    }, [data, isMarkedAsPersonalTask]);

    useEffect(() => {
      if (!tags.length) {
        dispatch(getList(""));
      }
    }, []);

    useEffect(() => {
      setIsEdit(!!data.name.trim() && !isEqual(data, task ? task : tempTask));
    }, [data]);

    const handleData = (name: string, value: any) => {
      setData((data) => ({
        ...data,
        [name]: value,
      }));
    };

    const delegateTask = useCallback(async () => {
      if (isMarkedAsPersonalTask) return;

      dispatch(delegate({ id: data.id, userId: data.delegatedTo, date: dateNow }));
    }, [data]);

    const handleSave = async () => {
      const completeSaving = (isFullfield: boolean) => {
        if (isFullfield) {
          removeUploadedFilesFromServer();
          handleClose();
        }
      };

      if (isMarkedAsPersonalTask) {
        const response = await dispatch(
          createPersonal({ body: { ...buildPersonalTaskDataBody(data) }, isAdd: toFormatDate(dateNow) === data.startDate }),
        );
        handleClose();

        completeSaving(isFulfilledStatus(response));

        return;
      }

      if (data.delegatedTo) {
        setIsModalControlDelegateVisible(true);
        return;
      }

      if (attachEventsMeta) {
        data.attachedMeetings = attachEventsMeta.map((obj) => ({
          meetingId: obj.id,
          date: obj.date,
        }));
      }

      const response = await dispatch(create({ body: data, isAdd: toFormatDate(dateNow) === data.startDate }));

      completeSaving(isFulfilledStatus(response));
    };

    const handleEdit = async () => {
      if (!task) return;

      const body = diff(data, task);

      if (body.delegatedTo) {
        setIsNeedToDelayTaskDelegation(true);
        setIsModalControlDelegateVisible(true);
        return;
      } else if (body.delegatedTo === null) {
        await dispatch(takeMe(task.id));
      }

      const response = await dispatch(update({ id: task.id, isPersonalTask, data: body }));

      if (isFulfilledStatus(response)) {
        removeUploadedFilesFromServer();
        setIsSave(true);
      }
    };

    const pressSave = async () => {
      myTracker("SaveButtonClick");
      task ? handleEdit() : handleSave();
    };

    const handleSaveAddDays = (days: WorkDay[]) => {
      handleData("workDays", days);
    };

    const handleChangeDate = (days: WorkDay[]) => {
      const val = days[0].date;
      const tempWorkDays = [...data.workDays];
      const index = tempWorkDays.findIndex((i) => i.date === (data.startDate ?? dateNow.format("YYYY-MM-DD")));
      tempWorkDays.splice(index, 1);

      if (tempWorkDays.every((i) => i.date !== val)) {
        tempWorkDays.splice(index, 0, { ...tempWorkDay, date: val });
      }

      setData({
        ...data,
        workDays: tempWorkDays,
        startDate: val,
      });
    };

    const handleRemoveWorkDay = (date: string) => {
      handleData(
        "workDays",
        [...data.workDays].filter((i) => i.date !== date),
      );
    };

    const handleDelegate = (user: InterfaceUser.IStateUser) => {
      setData({
        ...data,
        delegatedToUser: { id: user.id, firstName: user.firstName, lastName: user.lastName, avatarFileId: user.avatarFileId },
        delegatedTo: user.id,
      });
      setisVisibleActions(false);
    };

    const handleTakeMe = () => {
      setData({
        ...data,
        delegatedToUser: null,
        delegatedTo: null,
      });

      if (task && shouldShowWorkdayReminder(task.workDays)) {
        setIsWorkDayReminderVisible(true);
      }
    };

    const outsidePressName = async (callback?: (...args: any) => Promise<void>) => {
      const tagId = (await propsUseTask.handleSaveTitle()) ?? tag;

      if (tagId === null || tagId.length === 0) return;

      handleData("tagId", tagId);
      propsUseTask.setText(propsUseTask.name);

      if (callback) await callback(tagId);
    };

    const month = useMemo(
      () => changeFirstAndLastLetter(dayjs.months()[dayjs(data.startDate ?? dateNow).month()]).toLowerCase(),
      [data.startDate, dateNow],
    );

    const handleCloseWrapper = () => {
      if (task) {
        setIsSave(true);
      } else {
        handleClose();
      }
    };

    const handleCancelWrapper = () => {
      setIsCancel(true);
    };

    useEffect(
      () => () => {
        dispatch(clearTagId());
      },
      [],
    );

    useEffect(() => {
      markAsPersonalTask(isPersonalTask);
    }, [isPersonalTask]);

    return (
      <>
        <HeaderModal
          title={task ? "editing" : "newTask"}
          leftSide={{ onPressClose: handleCloseWrapper, onPressCancel: handleCancelWrapper }}
          isEdit={isEdit}
          rightSide={{ onPress: pressSave, isLoading: isLoadingTask }}
          themeTextColor={theme.text.main}
          styleContainer={{ backgroundColor: theme.background.main }}
        />
        <div style={{ ...styles.main, overflow: "auto" }}>
          <div>
            <div style={styles.wrapperBlock}>
              <div style={styles.dateBlock}>
                {isScreenDay ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextFont style={styles.textThin}>
                      <FormattedMessage id="date" />:
                    </TextFont>
                    <TextFont type="bold" style={styles.dateText}>{`${dayjs(data.startDate ?? dateNow).format(
                      "DD",
                    )} ${month}, ${calendar.getWeekDayRu(dayjs(data.startDate ?? dateNow).day())}`}</TextFont>
                    <button onClick={() => setisVisibleCalendar(true)}>
                      <CalendarGray />
                    </button>
                  </div>
                ) : (
                  <TextFont size={18} weight="700" type="bold">
                    <FormattedMessage id="taskInformation" />
                  </TextFont>
                )}
              </div>
              <div style={{ position: "relative" }}>
                <button onClick={() => setisVisibleActions(true)}>
                  <SvgDotes />
                </button>

                <ModalRenderWithCondition condition={isVisibleActions}>
                  <Actions
                    isPersonalTask={isMarkedAsPersonalTask}
                    close={() => setisVisibleActions(false)}
                    componentDelegate={
                      task?.type !== "CONTROL" &&
                      !isMarkedAsPersonalTask && (
                        <ButtonDelegate
                          onSave={handleDelegate}
                          sizeIcon={16}
                          styleButton={{ padding: "8px", borderRadius: 0, marginTop: 0 }}
                          delegatedToUser={data.delegatedToUser}
                          task={data}
                          handleTakeMe={handleTakeMe}
                          isNeedCloseAfterSave
                        />
                      )
                    }
                    task={task}
                    openFilePicker={openFilePicker}
                  />
                </ModalRenderWithCondition>
              </div>
            </div>

            <RenderWithCondition condition={!task && !isPersonalTask && !disablePersonalTasks && !isModeAssistant}>
              <PersonalTaskCheckbox onValueChange={markAsPersonalTask} isChecked={isMarkedAsPersonalTask} />
            </RenderWithCondition>

            <Inputs
              name={propsUseTask.name}
              description={data.description}
              priority={data.priority}
              autoFocusName={Boolean(!task)}
              handleData={handleData}
              text={propsUseTask.text}
              onChangeTitle={propsUseTask.onChangeTitle}
              outsidePress={outsidePressName}
            />
            <Tag id={tag} handleData={handleData} />
          </div>

          <RenderWithCondition condition={permissons.WORK_WITH_SPACE_PROJECTS && !isMarkedAsPersonalTask}>
            <Project
              name={data?.project?.name ?? memoizedProjectName}
              handleData={handleData}
              bg={globalState.colorSchema.white}
              memoizeProjectName={setMemoizedProjectName}
            />
          </RenderWithCondition>

          <ModalRenderWithCondition condition={isVisibleAddDay}>
            <PlanningWork
              isVisible={isVisibleAddDay}
              setIsVisible={setIsVisibleAddDay}
              isTime={Boolean(data.timeEnabled)}
              startDays={data.workDays}
              startDate={data.startDate}
              tempObject={tempWorkDay}
              onSave={handleSaveAddDays}
              keyTitle="workDay"
              deadline={data.plannedEndDate}
              disable={{}}
              timeEnabled={data.timeEnabled}
              handleData={handleData}
            />
          </ModalRenderWithCondition>
          <Priority setPriority={handleData} priority={data.priority} />

          <RenderWithCondition condition={permissons.CAN_DELEGATE && isTaskDelegateAble}>
            {data.delegatedFromUser || data.delegatedToUser ? (
              <Delegate task={data} onSave={handleDelegate} onTakeMe={handleTakeMe} />
            ) : (
              <ButtonDelegate styleButton={{ backgroundColor: "white" }} onSave={handleDelegate} task={data} />
            )}
          </RenderWithCondition>

          <RenderWithCondition condition={!isMarkedAsPersonalTask}>
            <Goal id={data.businessGoalId === undefined ? data.businessGoal?.id : data.businessGoalId} handleData={handleData} />
          </RenderWithCondition>

          <Deadline
            date={data.plannedEndDate}
            startDate={toFormatDate(isScreenDay ? dateNow : dayjs())}
            handleData={handleData}
            field="plannedEndDate"
          />

          <ModalRenderWithCondition condition={isVisibleCalendar}>
            <PlanningWork
              isVisible={isVisibleCalendar}
              setIsVisible={setisVisibleCalendar}
              isTime={false}
              isMultiple={false}
              startDays={[{ date: data.startDate }]}
              onSave={handleChangeDate}
              startDate={dateNow.format("YYYY-MM-DD")}
              keyTitle="Изменить дату"
              selectedWorkDay={data.startDate}
              deadline={data.plannedEndDate}
              disable={{ style: false, button: false }}
              handleData={handleData}
              disableAddTimeToggle
            />
          </ModalRenderWithCondition>

          <WorkDays
            workDays={data.workDays}
            remove={handleRemoveWorkDay}
            onAddDays={() => setIsVisibleAddDay(true)}
            handleClose={handleClose}
          />

          <RenderWithCondition condition={isTask && !isMarkedAsPersonalTask}>
            <TaskMeetings taskId={data.id} allowMeetingsAttach />
          </RenderWithCondition>

          <FileAttachments
            handleData={handleData}
            allowFilePick
            allowFileDelete
            openFilePicker={openFilePicker}
            allFiles={allFiles}
            uploadedFiles={uploadedFiles}
            handleFileDownload={handleFileDownload}
            deleteFile={deleteFile}
          />

          <button
            disabled={!isEdit}
            style={{
              ...styles.button,
              ...styles.buttonSave,
              ...{
                backgroundColor: isMarkedAsPersonalTask ? globalState.colorSchema.darkPurple : theme.background.green,
              },
              ...(!isEdit && { backgroundColor: "#E3E9E7" }),
            }}
            onClick={pressSave}
          >
            <LoaderButtonRenderWithCondition condition={isLoadingTask}>
              <TextFont style={styles.buttonText}>
                <FormattedMessage id="save" />
              </TextFont>
            </LoaderButtonRenderWithCondition>
          </button>

          <ModalRenderWithCondition condition={propsUseTask.isErrorTitle}>
            <Notification type="clear" phraseId="tagDescription" phraseOkId="clear" onOk={() => propsUseTask.setIsErrorTitle(false)} />
          </ModalRenderWithCondition>

          <ModalRenderWithCondition condition={isModalControlDelegateVisible}>
            <ModalControlDelegate
              closeParent={() => {
                handleClose();
              }}
              close={() => setIsModalControlDelegateVisible(false)}
              deadline={data.plannedEndDate}
              delegatedTaskData={data}
              isDelegatedTaskSaved={!!task}
              isNeedUpdateTask={!!task}
              delegateTask={isNeedToDelayTaskDelegation ? delegateTask : null}
            />
          </ModalRenderWithCondition>

          <ModalRenderWithCondition condition={isAddWorkDayReminderVisible && Boolean(task)}>
            <AddWorkDaysReminder
              setIsVisible={setIsWorkDayReminderVisible}
              isVisible={isAddWorkDayReminderVisible}
              setIsVisibleAddDay={setIsVisibleAddDay}
            />
          </ModalRenderWithCondition>
        </div>
      </>
    );
  },
);
