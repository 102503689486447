import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { resetStore } from "@constants/logic";
import { ITask } from "@interfaces/businessGoals.interface";
import { IMeet } from "@interfaces/meet.interface";
import { sortMeetingsSeries } from "@screens/day/components/ListTasks/Modal/components/taskMeetings/utils";
import { IAttachEventMeta } from "@services/meet.service";

import { getMeetingsSeries, unattachEvent } from "./api";

export interface IMeetingSeries {
  day: string;
  data: IMeet[];
}

interface IVerboseTask extends ITask {
  attachedMeetings: IMeet[];
}

interface IInitialState {
  isLoading: boolean;
  errorMsg: null | string;
  selectedItemsIds: string[];
  meetingId: string;
  eventsMeta: IAttachEventMeta[];
  verboseTasks: IVerboseTask[];
  contextTask: IVerboseTask | null;
  selectedMeetings: IMeet[];
  meetingsSeries: IMeetingSeries[];
}

const initialState: IInitialState = {
  isLoading: false,
  errorMsg: null,
  meetingId: "",
  selectedItemsIds: [],
  eventsMeta: [],
  verboseTasks: [],
  selectedMeetings: [],
  meetingsSeries: [],
  contextTask: null,
};

const attachedMeetingTasksSlice = createSlice({
  name: "attachedMeetingTasks",
  initialState,
  reducers: {
    addSelectedItemId: (state, action: PayloadAction<string>) => {
      const uniqueIds = new Set([...state.selectedItemsIds, action.payload]);

      state.selectedItemsIds = Array.from(uniqueIds);
    },
    removeSelectedItemId: (state, action: PayloadAction<string>) => {
      state.selectedItemsIds = state.selectedItemsIds.filter((id) => id !== action.payload);
    },
    resetAttachedTasks: () => initialState,
    resetSelectedItemIds: (state) => {
      state.selectedItemsIds = [];
    },
    addSelectedMeetings: (state, action: PayloadAction<IMeet[] | undefined>) => {
      if (!action.payload) return;

      const uniqueEntities = new Map();
      const addObjectToMap = (object: IMeet) => {
        if (!uniqueEntities.has(object.id)) {
          uniqueEntities.set(object.id, object);
        }
      };

      state.selectedMeetings.forEach(addObjectToMap);
      action.payload.forEach(addObjectToMap);

      state.selectedMeetings = Array.from(uniqueEntities.values()).sort(
        (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
      );
    },
    removeSelectedMeetingById: (state, action: PayloadAction<string>) => {
      state.selectedMeetings = [...state.selectedMeetings.filter((meeting) => meeting.id !== action.payload)];
    },
    addVerboseTasks: (state, action: PayloadAction<IVerboseTask[]>) => {
      state.verboseTasks = [...state.verboseTasks, ...Array.from(action.payload)];
    },
    addContextTask: (state, action: PayloadAction<IVerboseTask>) => {
      state.contextTask = action.payload;
    },
    resetMeetingsSeries: (state) => {
      state.meetingsSeries = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    addEventsMeta: (state, action: PayloadAction<IAttachEventMeta[]>) => {
      state.eventsMeta = [...state.eventsMeta, ...action.payload];
    },
    removeItemFromEventsMeta: (state, action: PayloadAction<string>) => {
      state.eventsMeta = [...state.eventsMeta.filter((item) => item.id !== action.payload)];
    },
    resetAttachedEventsStore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(getMeetingsSeries.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getMeetingsSeries.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = null;
        state.meetingsSeries = [...state.meetingsSeries, ...sortMeetingsSeries(payload.response.data)];
      })
      .addCase(getMeetingsSeries.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })
      .addCase(unattachEvent.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(unattachEvent.fulfilled, (state) => {
        state.isLoading = false;
        state.errorMsg = null;
      })
      .addCase(unattachEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});

export const {
  addSelectedItemId,
  removeSelectedItemId,
  resetAttachedTasks,
  resetSelectedItemIds,
  addSelectedMeetings,
  addVerboseTasks,
  addContextTask,
  resetMeetingsSeries,
  removeSelectedMeetingById,
  setLoading,
  addEventsMeta,
  removeItemFromEventsMeta,
  resetAttachedEventsStore,
} = attachedMeetingTasksSlice.actions;
export default attachedMeetingTasksSlice.reducer;
