import { createSlice } from "@reduxjs/toolkit";

import { resetStore } from "@constants/logic";
import { IGoal } from "@interfaces/personalGoals.interface";

import { getGoal, getList, updateListTasks, statusTask } from "./api";

interface IInitialState {
  isLoading: boolean;
  errorMsg: null | string;
  goals: IGoal[];
  currentGoal: IGoal | null;
}

const initialState: IInitialState = {
  isLoading: false,
  errorMsg: null,
  goals: [],
  currentGoal: null,
};

const personalGoalsSlice = createSlice({
  name: "personalGoals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(getList.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.goals = payload?.response?.data ? payload?.response?.data : [];
      })
      .addCase(getList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(getGoal.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getGoal.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.goals = [...state.goals, { ...payload.response.data, personalTasks: payload.responseTasks.data }];
      })
      .addCase(getGoal.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(updateListTasks.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(updateListTasks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const tempState = [...state.goals];

        tempState.filter((i) => i.id === payload.goalId)[0].personalTasks = payload.response.data;

        state.goals = tempState;
      })
      .addCase(updateListTasks.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(statusTask.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(statusTask.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(statusTask.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      });
  },
});

export default personalGoalsSlice.reducer;
