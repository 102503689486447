import React, { useState } from "react";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, weekendsOnWorkDays } from "@constants/calendar";
import { memoizedDayColor } from "@shared/calendar/monthCalendar/utils";
import { saveDate } from "@store/screenDay";
import { themeSelector } from "@store/theme";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg } from "@ui";
import { calendar } from "@utils";
import globalState from "@utils/globalState";

const daysOfWeekDefault = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

export const Header = ({ startDate, selectedDay }: any) => {
  const dispatch = useDispatch();
  const { extra } = themeSelector();

  const [tempWeek, setTempWeek] = useState<number>(0);

  const daysOfWeek: any = calendar.getDaysOfWeek(selectedDay);

  const isWeekendOnWorkDay = (date: string) => weekendsOnWorkDays.includes(date);

  const isWeekend = (day: string, daysOfWeek: any) => {
    const weekDay = dayjs(daysOfWeek[day]).day();
    const isWeekendDay = weekDay === 0 || weekDay === 6;
    const dateFormatted = dayjs(daysOfWeek[day]).format("YYYY-MM-DD");
    const isHoliday = isWeekendOnWorkDay(dateFormatted);
    return isWeekendDay || isHoliday;
  };

  return (
    <section style={{ ...styles.container, position: "relative" }}>
      <div style={styles.row}>
        {daysOfWeekDefault.map((day, index) => (
          <div style={styles.cell} key={index}>
            <TextFont
              style={{
                ...styles.text,
                color: isWeekend(day, daysOfWeek) ? "#FF3B30" : globalState.colorSchema.placeholder.main,
              }}
            >
              {day.charAt(0)}
            </TextFont>
          </div>
        ))}
      </div>
      <div style={styles.row}>
        {daysOfWeekDefault.map((day, index) => {
          const numberDay = dayjs(daysOfWeek[day]).date();
          const numberMonth = dayjs(daysOfWeek[day]).month();
          const numberYear = dayjs(daysOfWeek[day]).year();
          const isToday =
            numberDay === dayjs(startDate).date() && numberMonth === dayjs(startDate).month() && numberYear === dayjs(startDate).year();

          const isSelectedDay =
            numberDay === dayjs(selectedDay).date() &&
            numberMonth === dayjs(selectedDay).month() &&
            numberYear === dayjs(selectedDay).year();

          const weekDay = dayjs(daysOfWeek[day]).day();
          const isNotCurrentMonth = numberMonth !== dayjs(selectedDay).month();
          const dayForEvents = dayjs(daysOfWeek[day]).format("YYYY-MM-DD");

          const textColor = memoizedDayColor(
            weekDay,
            isSelectedDay,
            isToday,
            isNotCurrentMonth,
            weekendsOnWorkDays,
            dayForEvents,
            extra === "ASSISTANT" ? Colors.ASSISTANT : Colors.LIGHT,
          );

          return (
            <Cell isAssistantTheme={extra === "ASSISTANT"} isSelected={isSelectedDay} isToday={isToday} key={index}>
              <button
                onClick={() => {
                  dispatch(saveDate(daysOfWeek[day]));
                }}
              >
                <TextFont
                  style={{
                    ...(isSelectedDay && styles.textSelected),
                    ...(isToday && styles.textToday),
                    color: textColor,
                  }}
                  key={index}
                >
                  {numberDay}
                </TextFont>
              </button>
            </Cell>
          );
        })}
      </div>
      <button
        onClick={() => {
          dispatch(saveDate(selectedDay.add(7, "day")));
          setTempWeek(tempWeek + 7);
        }}
        style={{ ...styles.arrow, ...styles.arrowNext, position: "absolute" }}
      >
        <ArrowSvg type="right" fill={"#5F6E67"} width={16} height={16} style={{ left: 6 }} />
      </button>
      <button
        onClick={() => {
          dispatch(saveDate(selectedDay.subtract(7, "day")));
          setTempWeek(tempWeek - 7);
        }}
        style={{ ...styles.arrow, ...styles.arrowPrev, position: "absolute" }}
      >
        <ArrowSvg type="left" fill={"#5F6E67"} width={16} height={16} style={{ left: 6 }} />
      </button>
    </section>
  );
};

const styles = {
  container: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  row: {
    display: "flex",
    width: "100%",
    "flex-direction": "row",
    justifyContent: "space-between",
  },
  cell: {
    display: "flex",
    "flex-direction": "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    borderRadius: "20px",
  },
  cellToday: {
    backgroundColor: globalState.colorSchema.background.green,
    borderRadius: "20px",
  },
  cellSelected: {
    backgroundColor: "#5F6E67",
    borderRadius: "20px",
  },
  text: {
    color: globalState.colorSchema.placeholder.main,
  },
  textToday: {
    color: globalState.colorSchema.white,
  },
  textSelected: {
    color: globalState.colorSchema.white,
  },
  arrow: {
    top: "30px",
  },
  arrowNext: {
    right: "-24px",
  },
  arrowPrev: {
    left: "-24px",
  },
};

const Cell = styled.div<{ isAssistantTheme: boolean; isToday: boolean; isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;

  ${(props) =>
    props.isToday &&
    `
    background-color: ${props.isAssistantTheme ? Colors.ASSISTANT.green : Colors.LIGHT.background.green};
  `}

  ${(props) =>
    props.isSelected &&
    `
    background-color: ${props.isAssistantTheme ? Colors.ASSISTANT.background.green : Colors.LIGHT.grey500};
  `}
`;
