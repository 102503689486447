import { createSlice } from "@reduxjs/toolkit";

import { resetStore } from "@constants/logic";
import { INote } from "@interfaces/note.interface";

import { getList, createNote, updateNote, deleteNote } from "./api";

interface IInitalState {
  isLoading: boolean;
  notes: INote[];
  errorMsg: null | string;
  notePosition: number | null;
}

const initialState: IInitalState = {
  isLoading: false,
  notes: [],
  errorMsg: null,
  notePosition: null,
};

const noteSlice = createSlice({
  name: "note",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(getList.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getList.fulfilled, (state, { payload }) => {
        state.notes = payload.response.data;
        state.isLoading = false;
      })
      .addCase(getList.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(createNote.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(createNote.fulfilled, (state, { payload }) => {
        state.notes = [payload.response.data, ...state.notes];
        state.isLoading = false;
      })
      .addCase(createNote.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(updateNote.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(updateNote.fulfilled, (state, { payload }) => {
        const tempNotes = [...state.notes];

        const elementIndex = tempNotes.findIndex((item) => item.id === payload.id);
        const data = { ...tempNotes[elementIndex], ...payload.response.data };
        tempNotes.splice(elementIndex, 1, data);

        state.notes = tempNotes;
        state.isLoading = false;
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })

      .addCase(deleteNote.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(deleteNote.fulfilled, (state, { payload }) => {
        state.notes = [...state.notes].filter((item) => item.id !== payload.id);
        state.isLoading = false;
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});
export default noteSlice.reducer;
