import React, { FC, useEffect, useState } from "react";

import { IMeetParticipants } from "@interfaces/meet.interface";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";

import { TFilter, titleFilter, tempFilters } from "../constants";

interface IProps {
  filter: TFilter;
  onPress: (type: TFilter) => void;
  participants: IMeetParticipants[];
  allParticipants: number;
  externalUsersLength: number;
  isAuthor: boolean;
}

export const Filters: FC<IProps> = ({ participants, onPress, allParticipants, externalUsersLength, filter, isAuthor }) => {
  const [filters, setFilters] = useState<Record<TFilter, number>>({ ...tempFilters });

  useEffect(() => {
    const result = { ...tempFilters };

    participants.forEach((participant) => {
      if (participant.status === "ATTENDER" || participant.status === "OWNER") result.attender++;
      if (participant.status === "NON_ATTENDER") result.non_attender++;
      if (participant.status === "MAYBE_ATTENDER") result.maybe_attender++;
      if (!participant.status) result.non_answer++;
      if (participant.statusComment && isAuthor) result.with_comment++;
      result.all = allParticipants;
    });

    setFilters({ ...result, non_answer: result.non_answer + externalUsersLength });
  }, [allParticipants, externalUsersLength, isAuthor, participants]);

  return (
    <section style={styles.container}>
      {Object.keys(filters).map(
        (i) =>
          !!filters[i] && (
            <button
              key={i}
              style={{ ...styles.btn, ...(filter === i && { border: `1px solid ${Colors.LIGHT.background.green}` }) }}
              onClick={() => onPress(i as TFilter)}
            >
              <TextFont color={"#121212E5"}>{titleFilter[i as TFilter]}</TextFont>
              <div style={styles.amount}>
                <TextFont color={"#12121299"}>{filters[i]}</TextFont>
              </div>
            </button>
          ),
      )}
    </section>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex",
    gap: 8,
    "flex-wrap": "wrap",
    marginTop: 8,
    marginBottom: 8,
  },
  btn: {
    display: "flex",
    gap: 4,
    padding: "4px 6px",
    alignItems: "center",
    backgroundColor: "rgba(18, 18, 18, 0.04)",
    borderRadius: 6,
    border: "0.5px solid rgba(18, 18, 18, 0.16)",
  },
  amount: {
    backgroundColor: "rgba(18, 18, 18, 0.04)",
    borderRadius: 2,
    paddingLeft: 1,
    paddingRight: 1,
  },
};
