/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { IExternalUser } from "@services/meet.service";

export function isInnerMeetParticipant(user: Record<string, any>): user is IMeetParticipants {
  return "permissions" in user || "user" in user || "workPosition" in user;
}

export function isStateUser(user: Record<string, any>): user is IStateUser {
  return "permissions" in user && "roles" in user && "department" in user && "workPosition" in user;
}

export function isExternalUser(user: Record<string, any>): user is IExternalUser {
  const isNestedUserProp = Object.prototype.hasOwnProperty.call(user, "user");

  return (
    ("isExternalUser" in user && user.isExternalUser === true) ||
    !(
      "roles" in (isNestedUserProp ? user.user : user) ||
      "permissions" in (isNestedUserProp ? user.user : user) ||
      "roles" in user ||
      "permissions" in user ||
      "workPosition" in user
    )
  );
}
