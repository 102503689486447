import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { isEqual } from "lodash";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { InterfaceBusinessTask, InterfaceUser } from "@interfaces";
import { DelegatedList } from "@shared/delegatedList/DelegatedList";
import { setFilter } from "@store/businessTask";
import { HeaderModal, ModalUI, TextFont } from "@ui";
import globalState from "@utils/globalState";

interface IProps {
  isVisible: boolean;
  close: () => void;
  initUsers: InterfaceUser.IStateUser[];
  title: "delegatedTo" | "delegatedFrom";
}

export const DelegatedTab: FC<IProps> = ({ isVisible, close, initUsers, title }) => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState(initUsers);

  useEffect(() => {
    dispatch(setFilter({ [`${title}Type`]: null }));
  }, []);

  const addUser = useCallback(
    (user: InterfaceUser.IStateUser) => {
      if (!users.find((i) => i.id === user.id)) {
        setUsers([...users, user]);
      }
    },
    [users],
  );

  const removeUser = (id: string) => {
    setUsers([...users].filter((i) => i.id !== id));
  };

  const onSave = useCallback(() => {
    dispatch(setFilter({ [title]: users.map((i) => i.id), [`${title}Users`]: users }));
    close();
  }, [users]);

  const handleButton = (type: InterfaceBusinessTask.TDelegatedFilter) => {
    dispatch(setFilter({ [`${title}Type`]: type, [title]: [], [`${title}Users`]: [] }));
    close();
  };

  const isEdit = useMemo(() => !isEqual(users, initUsers), [users, initUsers]);

  return (
    <ModalUI isVisible={isVisible} onClose={close}>
      <HeaderModal
        title="userChoice"
        leftSide={{ onPressClose: close, isHideCancel: true }}
        isEdit={isEdit}
        rightSide={{ title: "apply", onPress: onSave }}
      />

      <div style={styles.header}>
        <div style={styles.buttons}>
          <button
            style={{ ...styles.button, marginBottom: 10 }}
            onClick={() => handleButton(title === "delegatedFrom" ? "DELEGATED_FROM_ME" : "DELEGATED_TO_ME")}
          >
            <TextFont>Делегировано</TextFont>
            <TextFont type="bold" weight="700">
              {title === "delegatedFrom" ? " ОТ " : " НА "}
            </TextFont>
            <TextFont>
              <FormattedMessage id={"delegatedMe"} />
            </TextFont>
          </button>
          <button style={styles.button} onClick={() => handleButton(title === "delegatedFrom" ? "DELEGATED_TO_ME" : "DELEGATED_FROM_ME")}>
            <TextFont>Делегировано</TextFont>
            <TextFont type="bold" weight="700">
              {title === "delegatedFrom" ? " ОТ " : " НА "}
            </TextFont>
            <TextFont>
              <FormattedMessage id={"delegatedAny"} />
            </TextFont>
          </button>
        </div>

        <RenderWithCondition condition={!!users.length}>
          <div style={{ marginTop: 30 }}>
            <TextFont style={{ marginBottom: 9 }}>
              <FormattedMessage id={title} />
            </TextFont>

            <div style={styles.users}>
              {users.map((user) => (
                <button onClick={() => removeUser(user.id)} key={user.id} style={styles.user}>
                  <TextFont style={{ marginRight: 5 }}>{`${user.lastName} ${user.firstName}`}</TextFont>
                  <SvgRemove fill={globalState.colorSchema.background.grey} width={9} height={9} />
                </button>
              ))}
            </div>
          </div>
        </RenderWithCondition>
      </div>

      <DelegatedList close={close} onSave={addUser} isModal={true} isNeedCloseAfterSave={false} />
    </ModalUI>
  );
};

const styles = {
  header: {
    padding: "14px 19px",
  },
  buttons: {
    display: "flex",
    "flex-wrap": "wrap",
    alignItems: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    "text-align": "center",
    gap: "3px",
    padding: 7,
    borderRadius: 5,
    border: `1px solid ${globalState.colorSchema.green}`,
    backgroundColor: globalState.colorSchema.lighGrey,
    marginRight: 5,
  },
  users: {
    display: "flex",
    "flex-wrap": "wrap",
    alignItems: "center",
  },
  user: {
    display: "flex",
    alignItems: "center",
    borderRadius: 45,
    padding: "3px 9px",
    marginRight: 6,
    backgroundColor: globalState.colorSchema.lighGrey,
    marginBottom: 6,
  },
};
