import React from "react";

import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { RenderWithCondition } from "@hoc";
import { Projects } from "@shared/projects";
import { TextFont, ModalUI } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import globalState from "@utils/globalState";

interface IModalProject {
  isVisible: boolean;
  close: () => void;
  projectName?: string;
  handleProject: (name: string, value: any, text?: string) => void;
  isFilter?: boolean;
  title?: string;
  handleProjectName?: (name: string | null) => void;
}

export const ModalProject = ({
  isVisible,
  close,
  handleProject,
  isFilter = false,
  projectName,
  title = "projects",
  handleProjectName,
}: IModalProject) => {
  const selectProject = (key: string, value: string, text = "") => {
    handleProject(key, value);
    if (handleProjectName) {
      handleProjectName(text);
    }

    close();
  };

  const deleteProject = () => {
    if (isFilter) {
      handleProject("projectGroupId", null, "");
      handleProject("projectId", null, "");
    } else {
      handleProject("projectId", null, "");
    }

    if (handleProjectName) {
      handleProjectName(null);
    }
  };

  return (
    <ModalUI scrollEnable={false} isVisible={isVisible} onClose={close}>
      <HeaderModal title={title} leftSide={{ onPressClose: close }} />
      <RenderWithCondition condition={projectName}>
        <div style={styles.projectWrapper}>
          <div style={styles.projectBlock}>
            <TextFont style={{}}>{projectName}</TextFont>

            <button onClick={deleteProject}>
              <SvgRemove fill="#B3BEB8" />
            </button>
          </div>
        </div>
      </RenderWithCondition>
      <Projects style={{ paddingBottom: 20 }} handleProject={selectProject} isFilter={isFilter} />
    </ModalUI>
  );
};

const styles = {
  projectWrapper: {
    display: "flex",
    backgroundColor: globalState.colorSchema.background.darkGreen,
    paddingRight: "12px",
    paddingLeft: "20px",
    paddingTop: "11px",
    paddingBottom: "11px",
    "justify-content": "flex-start",
  },
  projectBlock: {
    display: "flex",
    alignItems: "center",
    borderRadius: "45px",
    backgroundColor: "#fff",
    paddingLeft: "9px",
    paddingRight: "9px",
    paddingTop: "3px",
    paddingBottom: "3px",
    height: "28px",
  },
  text: {
    fontSize: 14,
    width: "90%",
    marginRight: 10,
  },
};
