import React, { FC, useEffect, useCallback, useMemo, useState } from "react";

import FlatList from "flatlist-react/lib";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { ReactComponent as SvgPlus } from "@assets/plus.svg";
import { ReactComponent as SvgRemove } from "@assets/remove.svg";
import { ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { LetterAvatar } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { UserAvatar } from "@shared/userAvatar/UserAvatar";
import { selectExternalUsers } from "@store/externalUsers";
import { removeExternalUser, resetExternalUsers } from "@store/externalUsers/slice";
import { userSelector } from "@store/user";
import { commonStyles } from "@styles";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";
import { isTablet } from "@utils/index";
import { isInnerMeetParticipant, isExternalUser as isExternalUserType } from "@utils/index";

import { ReactComponent as SvgOwner } from "../../participants/assets/owner.svg";
import { ParticipantsList } from "../../participants/participantsList/ParticipantsList";
import { ICommonFields } from "../../viewNotSave/types";

import { HEIGHT_BLOCK } from "./Activity/constants";

const sizeIcon = isTablet ? 32 : 45;

interface IProps extends ICommonFields {
  data: IMeet;
  setParticipantLight: (arg: string) => void;
  participantLight: string;
  search?: JSX.Element;
  isUserHasEditPermissions: boolean;
  participantsAttendance: IParticipantAttendance[] | null;
  deletableUserIds?: Set<string>;
  deletedExternalUsersIds?: string[];
}

export const Participants: FC<IProps> = ({
  handleData,
  data,
  participantLight,
  setParticipantLight,
  search,
  isUserHasEditPermissions = false,
  participantsAttendance,
  deletedExternalUsersIds,
}) => {
  let index = 0;
  const [isVisible, setIsVisible] = useState(false);
  const { currentUser, directorId } = userSelector();
  const { externalUsers } = useSelector(selectExternalUsers);
  const dispatch = useDispatch();

  const handleDeleteItem = (id: string, isExternalUser: boolean) => {
    if (!data.participants || !handleData) return;

    if (isExternalUser) {
      dispatch(removeExternalUser(id));

      data.externalUsers &&
        handleData(
          "externalUsers",
          [...data.externalUsers].filter((i) => i.id !== id),
        );

      return;
    }

    handleData(
      "participants",
      [...data.participants].filter((i) => i.userId !== id),
    );
  };

  const handleAddUser = (user: IStateUser, isExternalUser = false) => {
    if (isExternalUser) {
      handleData("externalUsers", [...(data?.externalUsers ?? []), { ...user, isExternalUser: true }]);
    } else {
      handleData("participants", [...(data?.participants ?? []), { ...user, status: null, userId: user.id, isExternalUser: false }]);
    }

    setIsVisible(false);
  };

  const getUser = useCallback(
    (userId: string) => {
      const participant = data.participants?.find((i) => i.userId === userId);
      return participant?.user ? participant?.user : (participant as unknown as IStateUser);
    },
    [data.participants],
  );

  const renderItem = (item: IMeetParticipants, i: number) => {
    const attendance = participantsAttendance?.filter((i) => i.userId === item.userId)[0];
    const isInnerUser = isInnerMeetParticipant(item);
    const isExternalUser = isExternalUserType(item);

    const itemId = item?.user?.id ?? item.id;
    const isMatchedIds = directorId ? true : currentUser?.id !== itemId;
    const isItemDeleteAllowed = isUserHasEditPermissions && item.status !== "OWNER" && isMatchedIds;
    const isDeleted = isExternalUser && deletedExternalUsersIds.some((id) => id === itemId);

    const isInnerBusy = isInnerUser ? item?.busy : false;
    const isBusy = search ? attendance?.meetings?.length : isInnerBusy;

    return (
      <button
        key={item.userId}
        style={{
          ...styles.item,
          cursor: isExternalUser ? "default" : "pointer",
          boxSizing: "content-box",
          ...(participantLight === item.userId && !isExternalUser
            ? {
                border: `0.5px solid ${Colors.LIGHT.background.grey}`,
              }
            : { borderBottom: "1px solid #E3E9E7" }),
        }}
        // onContextMenu={(e) => handleRightClick(e, item.id)}
        onClick={() => !isExternalUser && setParticipantLight(item.userId)}
      >
        <div style={{ display: "flex", flex: 8, height: "100%" }}>
          <div style={{ display: "flex" }}>
            <TextFont style={styles.itemNumber}>{item.status !== "OWNER" ? `${i}.` : " "}</TextFont>
            <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
              <RenderWithCondition condition={isExternalUser}>
                <LetterAvatar
                  userName={isExternalUser ? item?.firstName && item?.firstName[0]?.toUpperCase() : ""}
                  lastName={isExternalUser ? item?.lastName && item?.lastName[0]?.toUpperCase() : ""}
                  style={{ backgroundColor: "#A1B6AD", width: sizeIcon, height: sizeIcon }}
                  textStyle={{ color: globalState.colorSchema.white }}
                />
              </RenderWithCondition>
              <RenderWithCondition condition={!isExternalUser}>
                <UserAvatar
                  avatarFileId={(getUser(item.userId) && getUser(item.userId)["avatarFileId"]) ?? null}
                  style={{
                    width: sizeIcon,
                    height: sizeIcon,
                    borderRadius: sizeIcon,
                    marginRight: 8,
                    border: `2px solid ${isBusy ? Colors.LIGHT.red : Colors.LIGHT.green}`,
                  }}
                />
              </RenderWithCondition>
              <RenderWithCondition condition={item.status === "OWNER"}>
                <SvgOwner style={{ position: "absolute", right: 5, bottom: 0 }} />
              </RenderWithCondition>
            </div>
          </div>
          <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <TextFont
              type="bold"
              size={16}
              weight="700"
              style={{
                marginBottom: 0,
                ...commonStyles.oneOfLines,
                textDecorationLine: item?.deleted || isDeleted ? "line-through" : "none",
              }}
            >
              {isExternalUser
                ? `${item?.firstName} ${item?.lastName}`
                : `${getUser(item.userId)?.lastName ?? ""} ${getUser(item.userId)?.firstName ?? ""}`}
            </TextFont>
            <TextFont size={14} style={commonStyles.oneOfLines} color={Colors.LIGHT.placeholder.main}>
              {isExternalUser ? item?.email : getUser(item.userId)?.workPosition ?? ""}
            </TextFont>
          </div>
        </div>
        <RenderWithCondition condition={isItemDeleteAllowed}>
          <button
            style={styles.remove}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteItem(isExternalUser ? item.id : item.userId, isExternalUser);
            }}
          >
            <SvgRemove width={12} height={12} fill={globalState.colorSchema.background.grey} />
          </button>
        </RenderWithCondition>
      </button>
    );
  };

  const listData = useMemo(
    () => [...(data.participants ?? []), ...(data.externalUsers?.map((i) => ({ ...i, isExternalUser: true })) ?? [])],
    [data],
  );

  useEffect(() => {
    if (externalUsers.length === 0) {
      return;
    }

    if (data?.externalUsers && data?.externalUsers?.length !== 0) {
      for (const user of externalUsers) {
        for (const participant of data.externalUsers) {
          if (user.id === participant.id) {
            return;
          }

          handleAddUser(user, true);
        }
      }
    } else {
      for (const user of externalUsers) {
        handleAddUser(user, true);
      }
    }

    dispatch(resetExternalUsers());
  }, [data, externalUsers]);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <TextFont size={18} weight="700" type="bold">
          <FormattedMessage id="meetingMembers" />
        </TextFont>
        <RenderWithCondition condition={!search}>
          <button onClick={() => setIsVisible(true)}>
            <SvgPlus width={16} height={16} fill={Colors.LIGHT.background.grey} />
          </button>
        </RenderWithCondition>
      </div>

      {search}

      <RenderWithCondition condition={listData?.length}>
        <div style={styles.list}>
          <FlatList list={listData} renderItem={(item: IMeetParticipants) => renderItem(item, index++)} />
        </div>
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isVisible}>
        <ParticipantsList meet={data} onSave={handleAddUser} onClose={() => setIsVisible(false)} isMeet />
      </ModalRenderWithCondition>
    </div>
  );
};

const styles = {
  container: {
    marginTop: 30,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  list: {
    marginTop: 15,
    gap: 8,
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 8,
    width: "96%",
    height: HEIGHT_BLOCK,
  },
  itemNumber: {
    fontSize: 10,
    color: Colors.LIGHT.placeholder.main,
    alignSelf: "flex-end",
    width: 9,
    "word-break": "normal",
  },
  remove: {
    height: HEIGHT_BLOCK,
    alignItems: "flex-center",
    paddingRight: 8,
    paddingTop: 8,
  },
  button: {
    alignItems: "center",
    bottom: 0,
    justifyContent: "center",
    top: 0,
    width: 100,
    backgroundColor: Colors.LIGHT.red,
    marginBottom: isTablet ? 0 : 10,
    right: 7,
  },
  buttonText: {
    color: "#fff",
    fontSize: 10,
    fontWeight: "700",
    textAlign: "center",
    marginBottom: 2,
    padding: "0 3px",
    flex: 2,
  },
  buttonImg: {
    marginTop: 3,
    display: "flex",
    alignItems: "center",
    flex: 2.5,
  },
};
