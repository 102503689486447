import React, { useState, useEffect } from "react";

import cn from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import InputMask from "react-input-mask";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import { RECAPTCHA_SITE_KEY } from "@constants/settings";
import { LoaderButtonRenderWithCondition } from "@hoc/LoaderButtonRenderWithCondition";
import { useDimension } from "@hooks/useDimension";
import { getCodeApi } from "@store/auth";
import { authSelector } from "@store/auth/selectors";
import { clearError } from "@store/auth/slice";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui/TextFont";
import { myTracker } from "@utils/myTracker";

import backgroundTablet from "./assets/background-tablet.png";
import background from "./assets/background.png";
import logo from "./assets/logo.png";
import { ModalWarning } from "./components/modalWarning";
import "./auth.css";

export const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTablet } = useDimension();

  const { errorMsg, isLoading } = authSelector();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isVisibleModalWarning, setIsVisibleModalWarning] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const isDisabledButton = phoneNumber.length < 11 || !isVerified;

  useEffect(() => {
    if (errorMsg === "404") {
      setIsVisibleModalWarning(true);
    }
  }, [errorMsg]);

  const handleChange = (currentText: string) => {
    if (currentText.length < 2) {
      return;
    }
    return setPhoneNumber(currentText);
  };

  const handleAuth = async () => {
    myTracker("LogInClick");

    if (isDisabledButton) return;

    try {
      const response = await dispatch(getCodeApi(phoneNumber));
      if (response?.payload?.phoneNumber && !response?.error) {
        navigate("/checkCode");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn("handleAuth err", err); // TODO: fixed
    }
  };

  const closeHandlerModalWarning = () => {
    dispatch(clearError());
    setIsVisibleModalWarning(false);
  };

  const onLoginKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") {
      handleAuth();
    }
  };

  const handleRecaptcha = () => {
    setIsVerified(true);
  };

  return (
    <div style={{ overflow: "hidden", height: "100vh" }}>
      <Container isloading={isLoading}>
        <Header>
          <Image src={logo} />
        </Header>
        <div className={cn({ mainTablet: isTablet, main: !isTablet })}>
          <WrapperInput>
            <InputMask
              onKeyDown={onLoginKeyDown}
              value={phoneNumber}
              placeholder="+7"
              className="input"
              onChange={(event) => handleChange(event.target.value)}
              mask="+79999999999"
              maskChar=""
            />
            <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} />
            <Button
              disabled={isDisabledButton}
              onClick={handleAuth}
              className={cn("button", {
                buttonLoading: isLoading,
                disabledButton: isDisabledButton,
              })}
            >
              <LoaderButtonRenderWithCondition condition={isLoading}>
                <TextFont type="bold" className="buttonText" color="white">
                  <FormattedMessage id="logIn" />
                </TextFont>
              </LoaderButtonRenderWithCondition>
            </Button>
          </WrapperInput>
        </div>
        <Footer>
          <img src={isTablet ? backgroundTablet : background} style={styles.img} />
        </Footer>

        <ModalWarning isVisible={isVisibleModalWarning} closeEvent={closeHandlerModalWarning} />
      </Container>
    </div>
  );
};

const Container = styled.div<{ isloading: boolean }>`
  height: 100vh;
  display: grid;
  grid-template: 10% 35% 55%/ 1fr;
  background-color: ${() => Colors.LIGHT.background.main};
  pointer-events: ${(props) => (props.isloading ? "none" : "all")};
  overflow: hidden;
  padding-top: 60px;
`;

const WrapperInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const Image = styled.img`
  height: 100%;
`;

const Footer = styled.div`
  height: auto;
  width: 100%;
`;

const Button = styled.button`
  width: 100%;
`;

const styles = {
  img: {
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
  },
};
