import { http } from "@api/httpClient";
import { IPersonalSettings } from "@interfaces/userSettings.interface";

class UserSettingsService {
  constructor() {
    // do nothing
  }

  updateTasksDisplayPreferences = async (data: Record<string, string>): Promise<any> =>
    await http.patch("/api/api-gateway/v1/user-settings", data);

  getPersonalSettings = async (): Promise<IPersonalSettings> => await http.get("/api/settings/v1/user-settings");

  updatePersonalSettings = async (id: string, data: Record<string, string>): Promise<IPersonalSettings> =>
    await http.patch("/api/settings/v1/user-settings", data);

  authZoom = async (code: string): Promise<unknown> => await http.post(`/calendar/v1/zoom/auth/token/request?code=${code}`);
}

export const userSettings = new UserSettingsService();
