import React, { FC, memo, useMemo, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ReactComponent as SvgAttach } from "@assets/attach.svg";
import { ReactComponent as CloseIcon } from "@assets/close-thin.svg";
import { ReactComponent as DocumentAttachIcon } from "@assets/document-attach.svg";
import { regExpFileType } from "@constants/regExp";
import { LoaderRenderWithCondition, RenderWithCondition } from "@hoc";
import { IFile } from "@interfaces/files.interfaces";
import { modalStyles } from "@screens/day/components/ListTasks/Modal";
import { CommonFields } from "@screens/day/components/ListTasks/Modal/components/ViewNotSave/components/types";
import { fileService } from "@services/file.service";
import { selectFileAttachmentsStore } from "@store/fileAttachments/selectors";
import { commonStyles } from "@styles";
import { TextFont, Loader, ArrowSvg } from "@ui";
import { arePropsEqual } from "@utils/arePropsEqual";
import { convertFileTypeToBlob, TFileExtension } from "@utils/fileTypeToBlob";
import globalState from "@utils/globalState";

interface IFileAttachments extends Partial<CommonFields> {
  allowFilePick?: boolean;
  allowFileDelete?: boolean;
  uploadedFiles: IFile[];
  allFiles: IFile[];
  enableAddButton?: boolean;
  fileIdsExtractor?: (value: string[]) => void;
  onlyButton?: boolean;
  closeOverModal?: () => void;
  disable?: { value: boolean; press: () => void };
  isSaveCalled?: boolean;
  openFilePicker: () => void;
  deleteFile: (id: string) => void;
  handleFileDownload: (id: string, fileName: string) => void;
}

interface IFileListItem {
  name: string | null;
  allowDeletion?: boolean;
  onDelete: () => void;
  onPress: () => void;
}

const FileListItem: FC<IFileListItem> = ({ name, allowDeletion, onDelete, onPress }) => (
  <button style={commonStyles.billet}>
    <div onClick={onPress} style={styles.fileItemPressableArea}>
      <DocumentAttachIcon />
      <div style={styles.fileItemNameContainer}>
        <TextFont>{name ?? "<нет имени>"}</TextFont>
      </div>
    </div>
    {allowDeletion && (
      <button onClick={onDelete}>
        <CloseIcon />
      </button>
    )}
  </button>
);

export const FileAttachments: FC<IFileAttachments> = memo(function FileAttachments({
  handleData,
  fileIdsExtractor,
  allowFilePick,
  allowFileDelete,
  onlyButton = false,
  enableAddButton = true,
  disable,
  openFilePicker,
  allFiles = [],
  uploadedFiles,
  handleFileDownload,
  deleteFile,
}) {
  const { isLoading, errorMsg } = useSelector(selectFileAttachmentsStore);
  const isInitialLoaderVisible = useMemo(
    () => Boolean(!allFiles?.length) && isLoading && !errorMsg && !onlyButton,
    [allFiles, isLoading, errorMsg, onlyButton],
  );

  const onPress = () => {
    if (disable?.value) {
      return disable.press();
    }

    return openFilePicker();
  };

  const onPressFile = async (id: string, name: string) => {
    const matches = name.match(regExpFileType);

    if (matches) {
      const fileType = matches[0].toLowerCase() as TFileExtension;
      const blobType = convertFileTypeToBlob(fileType);
      return fileService.viewFile(id, blobType);
    }
  };

  useEffect(() => {
    fileIdsExtractor && fileIdsExtractor(uploadedFiles.map((file) => file.id));

    if (handleData && allFiles.length) {
      handleData(
        "fileIds",
        allFiles.map((file) => file.id),
      );
    } else {
      handleData && handleData("fileIds", []);
    }
  }, [allFiles, uploadedFiles]);

  return (
    <>
      <RenderWithCondition condition={Boolean(allFiles.length) || enableAddButton}>
        <div style={{ ...(isInitialLoaderVisible && { minHeight: "50px" }) }}>
          <LoaderRenderWithCondition condition={isInitialLoaderVisible}>
            <>
              {Boolean(allFiles.length) && !onlyButton ? (
                <div style={styles.fileAttachmentsContainer}>
                  <TextFont style={modalStyles.mainH1}>Прикрепленные файлы</TextFont>
                  <>
                    {allFiles.map((item) => (
                      <FileListItem
                        key={item.id}
                        name={item.fileName}
                        onDelete={() => deleteFile(item.id)}
                        onPress={() => onPressFile(item.id, item.fileName)}
                        allowDeletion={allowFileDelete}
                      />
                    ))}
                  </>
                  <RenderWithCondition condition={isLoading && !errorMsg}>
                    <div style={{ flex: 1 }}>
                      <Loader />
                    </div>
                  </RenderWithCondition>

                  <RenderWithCondition condition={allowFilePick}>
                    <>
                      <button style={commonStyles.billet} onClick={onPress}>
                        <TextFont style={{ ...modalStyles.blockTurnText, ...{ color: "#297952" } }}>
                          {"+ "}
                          <FormattedMessage id="attachFile" defaultMessage={"Добавить файл"} />
                        </TextFont>
                        <ArrowSvg type="right" fill="#B3BEB8" width={26} height={26} />
                      </button>
                    </>
                  </RenderWithCondition>
                </div>
              ) : (
                enableAddButton && (
                  <>
                    <button style={{ padding: 8, display: "flex", alignItems: "center" }} onClick={openFilePicker}>
                      <SvgAttach width={19} />
                      <TextFont
                        color={onlyButton ? globalState.colorSchema.text.main : globalState.colorSchema.placeholder.main}
                        style={{ marginLeft: 8 }}
                        size={onlyButton ? 16 : 18}
                      >
                        <FormattedMessage id="attachFile" />
                      </TextFont>
                    </button>
                  </>
                )
              )}
            </>
          </LoaderRenderWithCondition>
        </div>
      </RenderWithCondition>
    </>
  );
},
arePropsEqual);

const styles = {
  fileAttachmentsContainer: {
    display: "flex",
    "flex-direction": "column",
  },
  fileItemPressableArea: {
    display: "flex",
    "flex-direction": "row",
    width: "90%",
  },
  fileItemNameContainer: {
    width: "100%",
    "justify-content": "flex-start",
    "align-self": "center",
    display: "flex",
    "padding-left": "10px",
  },
};
