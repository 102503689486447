import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

import { resetStore } from "@constants/logic";

import { getFirstMonth } from "./api";

const initialState = {
  isLoading: false,
  date: dayjs(),
  startDate: "",
  errorMsg: null,
};

const dateSlice = createSlice({
  name: "dateSlice",
  initialState,
  reducers: {
    editMonth: (state, action: PayloadAction<"plus" | "minus">) => {
      state.date = state.date.add(action.payload === "plus" ? +1 : -1, "month");
    },
    saveMonth: (state, action: PayloadAction<Dayjs>) => {
      state.date = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(getFirstMonth.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getFirstMonth.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.startDate = payload?.response?.data?.firstPlannedYearMonth ?? dayjs().format("YYYY-MM");
      })
      .addCase(getFirstMonth.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      });
  },
});

export const { editMonth, saveMonth } = dateSlice.actions;
export default dateSlice.reducer;
