import { createSlice } from "@reduxjs/toolkit";

import { resetStore } from "@constants/logic";

import { getMonthGoal, saveMonthGoal } from "./api";

const initialState = {
  id: "",
  isLoading: false,
  description: "",
  errorMsg: null,
};

const monthGoalSlice = createSlice({
  name: "monthGoalSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(getMonthGoal.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getMonthGoal.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.description = payload.response.data.description ?? "";
        state.id = payload.response?.data?.id ?? "";
      })
      .addCase(getMonthGoal.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(saveMonthGoal.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(saveMonthGoal.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.description = payload.response.data.description;
      })
      .addCase(saveMonthGoal.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      });
  },
});

export default monthGoalSlice.reducer;
