import { createSlice } from "@reduxjs/toolkit";

import { resetStore } from "@constants/logic";
import { IUploadedFile } from "@shared/fileAttachments/useFileAttachments";
import { uploadFile, getFileInfo } from "@store/fileAttachments/api";

interface IInitialState {
  shouldRehydrate: boolean;
  selectedFiles: File[];
  uploadedFiles: IUploadedFile[];
  attachedFiles: IUploadedFile[];
  allFiles: IUploadedFile[];
  fileIdsToRemove: string[];
  errorMsg: string | null;
  isLoading: boolean;
}

const initialState: IInitialState = {
  shouldRehydrate: false,
  selectedFiles: [],
  uploadedFiles: [],
  attachedFiles: [],
  allFiles: [],
  fileIdsToRemove: [],
  errorMsg: null,
  isLoading: false,
};

const fileAttachmentSlice = createSlice({
  name: "fileAttachmentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(uploadFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadFile.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      })
      .addCase(getFileInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFileInfo.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getFileInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});

export default fileAttachmentSlice.reducer;
