import React, { memo, FC, useEffect, useState, useMemo, useCallback, useRef } from "react";

import dayjs, { Dayjs } from "dayjs";
import { FormattedMessage } from "react-intl";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";

import { ITask } from "@interfaces/businessGoals.interface";
import { IControlTask } from "@interfaces/controlTask.interface";
import { modalStyles } from "@screens/day/components/ListTasks/Modal";
import { Calendar } from "@shared/planningWork";
import { AttachEventsEventType, attachEvents } from "@store/attachedMeetingTasks/api";
import { selectEventsMeta } from "@store/attachedMeetingTasks/selectors";
import { create, createControl, selectBusinessTasks, update } from "@store/businessTask";
import { selectCurrentDate } from "@store/screenDay";
import { Colors } from "@theme/colors";
import { TextFont } from "@ui";
import { HeaderModal } from "@ui/headerModal/HeaderModal";
import { ModalUI } from "@ui/modal/ModalUI";
import globalState from "@utils/globalState";
import { showToast } from "@utils/toast";

import { generateWorkDays } from "./utils";

interface IModalControlDelegateProps {
  deadline?: string;
  delegatedTaskData: ITask;
  isDelegatedTaskSaved?: boolean;
  isNeedUpdateTask?: boolean;
  close: () => void;
  closeParent: () => void;
  onSave?: () => void;
  delegateTask?: () => void;
}

type TDay = {
  date: string;
};

interface ISelectedDaysListProps {
  days: TDay[];
}

const SelectedDaysList: FC<ISelectedDaysListProps> = ({ days }) => {
  const totalIndexes = days.length - 1;

  const sortedDays = useMemo(
    () =>
      days.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;

        return 0;
      }),
    [days],
  );

  const renderSelectedDays = useMemo(
    () =>
      sortedDays.reduce((accumulator, item, index) => {
        const formattedDate = dayjs(item.date).format("DD.MM");
        return accumulator + formattedDate + (totalIndexes !== index ? ", " : "");
      }, "Контроль "),
    [sortedDays],
  );

  return (
    <>
      <TextFont style={modalStyles.mainH1}>Что получилось:</TextFont>
      <div style={styles.selectedDaysListContainer}>
        <p style={styles.textDays}>{renderSelectedDays}</p>
      </div>
    </>
  );
};

export const ModalControlDelegate: FC<IModalControlDelegateProps> = memo(function ModalControlDelegate({
  deadline,
  delegatedTaskData,
  isDelegatedTaskSaved,
  close,
  closeParent,
  isNeedUpdateTask,
  delegateTask,
}: IModalControlDelegateProps) {
  const dispatch = useDispatch();
  const { isLoadingTask } = useSelector(selectBusinessTasks);
  const controlTaskData = useRef<IControlTask>();
  const startDays: TDay[] = [];
  const [days, setDays] = useState(startDays ?? []);
  const [isCreatingControlTask, setIsCreatingControlTask] = useState(false);
  const currentDay = dayjs(new Date()).toISOString();
  const dateNow = useSelector(selectCurrentDate);
  const attachEventsMeta = useSelector(selectEventsMeta);
  // ToDo: выяснить почему тоаст отображается за модалкой, если не использовать рендер через стейт
  const [displayToast, setDisplayToast] = useState(false);

  const handleCalendarDayPress = (dateWeek: Dayjs) => {
    const isSelect = !!days.find((i: any) => i.date === dateWeek.format("YYYY-MM-DD"));

    isSelect ? handleRemoveDay(dateWeek) : handleAddDay(dateWeek);
  };

  const handleAddDay = (date: Dayjs) => {
    const index = startDays.findIndex((i) => i.date === date.format("YYYY-MM-DD"));
    if (index !== -1) {
      setDays([...days, startDays[index]]);
    } else {
      setDays([...days, { date: date.format("YYYY-MM-DD") }]);
    }
  };

  const handleRemoveDay = (date: Dayjs) => {
    setDays([...days.filter((i: TDay) => i.date !== date.format("YYYY-MM-DD"))]);
  };

  const createDelegatedTask = async () => {
    try {
      const response = await dispatch(
        create({
          body: { ...delegatedTaskData },
          isAdd: delegatedTaskData["startDate"] ? dateNow.isSame(delegatedTaskData?.startDate, "date") : false,
          isPersonalTask: false,
        }),
      );

      return response?.payload?.response?.data;
    } catch (e) {
      showToast("somethingWentWrong", "error");
    }
  };

  const saveControlDelegatedTask = useCallback(async () => {
    if (!days.length) {
      setDisplayToast(true);
      return;
    }

    setIsCreatingControlTask(true);
    let createDelegatedTaskResponse = delegatedTaskData;

    if (!isDelegatedTaskSaved) {
      createDelegatedTaskResponse = (await createDelegatedTask()) as ITask;
    }

    const controlTask = {
      ...controlTaskData.current,
      name: `Контроль: ${controlTaskData?.current?.name}`,
      type: "CONTROL",
      controlledTaskId: createDelegatedTaskResponse.id,
      workDays: generateWorkDays(days),
    };

    if (isNeedUpdateTask) {
      await dispatch(
        update({
          id: delegatedTaskData.id,
          isPersonalTask: delegatedTaskData.subType === "PERSONAL",
          data: delegatedTaskData,
        }),
      );
    }

    delegateTask && delegateTask();

    await dispatch(createControl({ body: { ...controlTask } }));

    closeParent && closeParent();
    close();
  }, [controlTaskData.current, days]);

  const skipControlDelegation = async () => {
    if (!isDelegatedTaskSaved) {
      await createDelegatedTask();
    }

    if (attachEventsMeta.length && delegatedTaskData?.id) {
      dispatch(
        attachEvents({
          eventId: delegatedTaskData.id,
          eventsMeta: attachEventsMeta,
          eventType: AttachEventsEventType.MeetingsToTask,
        }),
      );
    }

    if (isNeedUpdateTask) {
      await dispatch(
        update({
          id: delegatedTaskData.id,
          isPersonalTask: delegatedTaskData.subType === "PERSONAL",
          data: delegatedTaskData,
        }),
      );
    }

    delegateTask && delegateTask();

    closeParent && closeParent();
    close();
  };

  const controlTaskTemplate: IControlTask = useMemo(
    () => ({
      controlledTaskId: "",
      name: delegatedTaskData.name,
      priority: delegatedTaskData.priority,
      startDate: delegatedTaskData.startDate,
      plannedEndDate: delegatedTaskData.plannedEndDate,
      projectId: delegatedTaskData.projectId,
      businessGoalId: delegatedTaskData.businessGoalId,
      workDays: delegatedTaskData.workDays,
      tagId: delegatedTaskData?.tagId ?? null,
      delegatedTo: delegatedTaskData.delegatedTo,
      unplanned: delegatedTaskData?.unplanned ?? true,
      attachedMeetings: delegatedTaskData?.attachedMeetings ?? [],
      fileIds: delegatedTaskData?.fileIds ?? [],
    }),
    [delegatedTaskData],
  );

  useEffect(() => {
    deadline && setDays([{ date: dayjs(deadline).format("YYYY-MM-DD") }]);
  }, []);

  useEffect(() => {
    controlTaskData.current = controlTaskTemplate;
  }, []);

  useEffect(() => {
    displayToast && showToast("selectDayToControl");
    setDisplayToast(false);
  }, [displayToast]);

  return (
    <ModalUI isVisible={true} onClose={close}>
      <HeaderModal title="modalControlDelegateTitle" leftSide={{ isHideCancel: true, onPressClose: close }} />
      <div style={styles.container}>
        <TextFont style={modalStyles.mainH1}>
          <FormattedMessage id="selectControlDays" defaultMessage="Выберете дни контроля делегированной задачи" />
        </TextFont>

        <div style={styles.calendarWrapper}>
          <Calendar
            days={days}
            currentDay={currentDay}
            startDay={currentDay}
            press={handleCalendarDayPress}
            type={"multituple"}
            highlightedDay={deadline}
          />
        </div>

        {!!days.length && <SelectedDaysList days={days} />}

        <button
          disabled={isLoadingTask}
          style={{
            ...styles.button,
            ...styles.buttonSave,
            ...(!days.length && { backgroundColor: "#E3E9E7" }),
            ...(isCreatingControlTask && styles.buttonDisabled),
            ...{ marginBottom: 10 },
          }}
          onClick={saveControlDelegatedTask}
        >
          {isCreatingControlTask ? (
            <div>
              <ReactLoading type="spokes" color={globalState.colorSchema.green} height={25} width={25} />
            </div>
          ) : (
            <TextFont style={styles.buttonSaveText}>
              <FormattedMessage id="createControlTask" defaultMessage={"Создать задачу контроля"} />
            </TextFont>
          )}
        </button>

        <button style={{ ...styles.button, ...styles.buttonSkipControl }} onClick={skipControlDelegation} disabled={isCreatingControlTask}>
          {isLoadingTask && !isCreatingControlTask ? (
            <ReactLoading type="spokes" color={globalState.colorSchema.green} height={25} width={25} />
          ) : (
            <TextFont style={styles.buttonSkipControlText}>
              <FormattedMessage id="notControlTask" defaultMessage={"Не контролировать делегированную задачу"} />
            </TextFont>
          )}
        </button>
      </div>
    </ModalUI>
  );
});

const styles = {
  container: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 9,
    paddingBottom: 12,
    flex: 1,
  },
  calendarWrapper: {
    marginTop: 10,
    marginBottom: 10,
  },
  selectedDaysListContainer: {
    "flex-direction": "row",
    "flex-wrap": "wrap",
    alignItems: "center",
  },
  itemListContainer: {
    flexDirection: "row",
  },
  textDays: {
    flexShrink: 1,
    fontSize: 18,
  },
  button: {
    display: "flex",
    width: "100%",
    "flex-direction": "row",
    "border-radius": 10,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 4,
    paddingRight: 4,
    "justify-content": "center",
  },
  buttonSave: {
    marginTop: 20,
    backgroundColor: Colors.LIGHT.background.green,
  },
  buttonSaveText: {
    fontSize: 18,
    fontWeight: "700",
    color: Colors.LIGHT.white,
  },
  buttonSkipControl: {
    "background-color": Colors.LIGHT.lighGrey,
  },
  buttonSkipControlText: {
    fontSize: 18,
    fontWeight: "700",
    color: Colors.LIGHT.text.grey,
    "text-align": "center",
  },
  buttonLoading: {
    paddingTop: 19,
    paddingBottom: 10,
  },
  buttonDisabled: {
    backgroundColor: "#E3E9E7",
  },
};
