import React, { FC, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { SETTINGS } from "@constants/settings";
import { TypeKeyUserSettings } from "@interfaces/configure.interface";
import { setSetting } from "@store/configure";
import globalState from "@utils/globalState";
import { secureStore } from "@utils/secureStore";

import { HeaderModal } from "../headerModal/HeaderModal";
import { ModalUI } from "../modal/ModalUI";
import { TextFont } from "../TextFont";

import { ReactComponent as SvgDescription } from "./assets/description.svg";
import { ReactComponent as SvgMouse_active } from "./assets/mouse_active.svg";
import { ReactComponent as SvgMouse_default } from "./assets/mouse_default.svg";
import { ReactComponent as SvgTask } from "./assets/task.svg";
import { Kind, img, title } from "./config";

interface IProps {
  kind: Kind;
  keySetting: TypeKeyUserSettings;
}

export const Prompting: FC<IProps> = ({ kind, keySetting }) => {
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [isStartAction, setIsStartAction] = useState(false);

  useEffect(() => {
    try {
      const settings = JSON.parse(secureStore.getValue(SETTINGS));
      setIsVisible(!settings[keySetting]);
    } catch (error) {
      setIsVisible(true);
    }
  }, [keySetting]);

  useEffect(() => {
    setTimeout(() => setIsStartAction(true), 2000);
  }, []);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => dispatch(setSetting({ key: keySetting, val: true })), 1000);
    }
  }, [isVisible, keySetting]);

  const close = () => {
    setIsVisible(false);
  };

  return (
    <ModalUI isVisible={isVisible} onClose={close}>
      <HeaderModal title={title[kind]} leftSide={{ onPressClose: close }} />
      <div style={styles.container}>
        <SvgDescription style={{ marginBottom: 60, width: "100%" }} />
        <div style={{ position: "relative" }}>
          <SvgTask style={{ width: "100%" }} />
          <div
            style={{
              position: "absolute",
              right: 243,
              bottom: 0,
              ...(isStartAction && { transform: "scale(0.8)" }),
              transition: "transform 300ms",
            }}
          >
            {isStartAction ? <SvgMouse_active /> : <SvgMouse_default />}
          </div>
          <div style={{ position: "absolute", right: 0, top: 83, opacity: isStartAction ? 1 : 0, transition: "opacity 300ms" }}>
            {img[kind]}
          </div>
        </div>
        <button style={styles.btn} onClick={close}>
          <TextFont style={{ textAlign: "center" }}>
            <FormattedMessage id="clear" />
          </TextFont>
        </button>
      </div>
    </ModalUI>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateRows: "auto auto 1fr",
    padding: "20px 15px 30px 15px",
    height: "87%",
  },
  title: {
    "text-align": "center",
    marginBottom: "10px",
  },
  btn: {
    backgroundColor: globalState.colorSchema.lighGrey,
    borderRadius: "10px",
    marginTop: "20px",
    padding: "16px 0",
    width: "100%",
    alignSelf: "flex-end",
  },
};
