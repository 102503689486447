import React, { FC, useState } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Toggle from "react-toggle";
import styled from "styled-components";

import { IDisableNotifications } from "@interfaces/userSettings.interface";
import { useSetDisableMeetingNotificationMutation } from "@services/meetApi";
import { selectScreenDay } from "@store/screenDay";
import { TextFont } from "@ui";
import globalState from "@utils/globalState";

import { NotificationName, NotificationTypes } from "../../../../../../../../../interfaces/userSettings.interface";

interface IAllowPush {
  handleData?: (name: string, value: unknown) => void;
  isCreating?: boolean;
  currentActivePush?: boolean;
}

export const AllowPush: FC<IAllowPush> = ({ handleData, isCreating, currentActivePush }) => {
  const [setDisableNotifications] = useSetDisableMeetingNotificationMutation();
  const [activeRemind, setActiveRemind] = useState(currentActivePush);
  const { currentEvent } = useSelector(selectScreenDay);

  const onHandleChange = async (status: boolean) => {
    setActiveRemind(status);

    const data: IDisableNotifications[] = [
      {
        notificationName: NotificationName.CONFIRM_MEETING_PARTICIPANT_STATUS,
        notificationTypes: status ? [] : [NotificationTypes.EMAIL, NotificationTypes.PUSH],
      },
    ];

    if (isCreating) {
      handleData && handleData("notificationDisableSettings", data);
    } else {
      const payload = status ? [] : data;
      await setDisableNotifications({ id: currentEvent?.id ?? "", data: payload });
    }
  };

  return (
    <Wrapper>
      <LineCheckBox>
        <TextFont weight="700" size={16}>
          <FormattedMessage id="getPushStatusMeeting" />
        </TextFont>
        <Toggle
          checked={activeRemind}
          onChange={(e) => onHandleChange(e.target.checked)}
          icons={false}
          colorOn={globalState.colorSchema.greenHover}
          colorOff={globalState.colorSchema.lighGrey}
          textColorOff={globalState.colorSchema.white}
          style={{ span: { background: globalState.colorSchema.white } }}
        />
      </LineCheckBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  // margin-top: -7px;
`;
const LineCheckBox = styled.div`
  display: flex;
  width: 100%;
  gap: 25px;
  background-color: ${() => globalState.colorSchema.white};
  padding: 12px;
  border-radius: 10px;
  height: 56px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
`;
