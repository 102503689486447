import React, { FC, useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { ModalRenderWithCondition } from "@hoc";
import { useTask } from "@hooks/useTask";
import { createUnplanned, selectBusinessTasks } from "@store/businessTask";
import { TextFont, HeaderModal, ModalUI } from "@ui";
import { Notification } from "@ui/notification/Notification";
import { NotificationConfirm } from "@ui/notification/NotificationConfirm";
import { toFormatDate } from "@utils";
import globalState from "@utils/globalState";

import { Project } from "../../../ListTasks/Modal/components/ViewNotSave/components";

import { useStartData } from "./useStartData";

interface IProps {
  close: () => void;
}

export const AdditionalTask: FC<IProps> = ({ close }) => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);

  const { data, handleData, isCancel, setIsCancel, dateNow } = useStartData();
  const { handleSaveTitle, onChangeTitle, isErrorTitle, setIsErrorTitle, text, name } = useTask({});

  const { isLoadingTask } = useSelector(selectBusinessTasks);

  useEffect(() => {
    setIsEdit(!!text);
  }, [text]);

  const onSubmit = async () => {
    const tagId = await handleSaveTitle();

    if (tagId === null) return;

    await dispatch(createUnplanned({ ...data, name, tagId, startDate: toFormatDate(dateNow) }));
    return close();
  };

  const plhName = useIntl().formatMessage({ id: "tagtask" });
  const plhDesc = useIntl().formatMessage({ id: "taskDescroption" });

  return (
    <ModalUI isVisible={true} onClose={isEdit ? () => setIsCancel(true) : () => close()}>
      <HeaderModal
        title={"additionalTask"}
        isEdit={isEdit}
        rightSide={{ title: "add", onPress: onSubmit, isLoading: isLoadingTask }}
        leftSide={{ onPressClose: close, onPressCancel: () => setIsCancel(true) }}
        styleContainer={{ borderBottomWidth: 0 }}
      />
      <div style={styles.content}>
        <TextFont style={styles.titleDate}>
          Дата:
          <TextFont type="bold" style={{ fontWeight: "700" }}>
            {` ${dateNow.locale("ru").format("D MMMM")}`}
          </TextFont>
        </TextFont>

        <textarea
          value={text}
          style={{ ...styles.input, minHeight: 72 }}
          placeholder={plhName}
          onChange={(e) => onChangeTitle(e.target.value)}
          maxLength={255}
        />

        <textarea
          value={data.description}
          onChange={(e) => handleData("description", e.target.value)}
          style={{ ...styles.input, minHeight: 56, paddingTop: 15 }}
          placeholder={plhDesc}
          maxLength={255}
        />

        <Project name={data?.project?.name} bg={globalState.colorSchema.white} handleData={handleData} />
      </div>

      <ModalRenderWithCondition condition={isErrorTitle}>
        <Notification type="clear" phraseId="tagDescription" phraseOkId="clear" onOk={() => setIsErrorTitle(false)} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isCancel}>
        <NotificationConfirm phraseId="cancelException" onOk={close} onCancel={() => setIsCancel(false)} />
      </ModalRenderWithCondition>
    </ModalUI>
  );
};

const styles = {
  titleDate: {
    display: "flex",
    "flex-direction": "row",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    marginBottom: "8px",
  },
  content: {
    paddingLeft: "9px",
    paddingRight: "15px",
    paddingTop: "20px",
    paddingBottom: "25px",
  },
  input: {
    borderRadius: "9px",
    backgroundColor: globalState.colorSchema.white,
    padding: "12px 16px",
    fontSize: "18px",
    marginBottom: "8px",
    width: "93%",
  },
};
