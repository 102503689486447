import React, { FC } from "react";

import dayjs from "dayjs";

import { TextFont, ModalUI, HeaderModal } from "@ui";

interface IProps {
  comment: string;
  date: string;
  close: () => void;
}

export const Comment: FC<IProps> = ({ comment, date, close }) => (
  <ModalUI isVisible onClose={close}>
    <HeaderModal title="comment" leftSide={{ onPressClose: close }} />

    <div style={styles.wrapper}>
      <TextFont type="bold" style={styles.title}>
        {dayjs(date).format("DD MMMM")}
      </TextFont>
      <TextFont>{comment}</TextFont>
    </div>
  </ModalUI>
);

const styles = {
  wrapper: {
    padding: "20px 12px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "700",
    marginBottom: "9px",
  },
  comment: {
    fontSize: "16px",
  },
};
