import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { resetStore } from "@constants/logic";
import { IGoal } from "@interfaces/businessGoals.interface";

import { create, deleteGoal, getList, update, moveDate, updateStatus, updateList, continueGoal } from "./api";

interface IInitialState {
  isLoading: boolean;
  errorMsg: null | string;
  goals: IGoal[];
  currentGoalId: string;
}

const initialState: IInitialState = {
  isLoading: false,
  errorMsg: null,
  goals: [],
  currentGoalId: "",
};

const businessGoals = createSlice({
  name: "businessGoals",
  initialState,
  reducers: {
    setCurrentId: (state, action: PayloadAction<string>) => {
      state.currentGoalId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(getList.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.goals = payload?.response?.data;
      })
      .addCase(getList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(create.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(create.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { data } = payload.response;

        if (data.yearMonths.includes(payload.yearMonth)) {
          state.goals = [...state.goals, data];
          state.currentGoalId = data.id;
        }
      })
      .addCase(create.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(updateList.pending, (state, payload) => {
        state.goals = payload.meta.arg;
        state.errorMsg = null;
      })
      .addCase(updateList.fulfilled, (state, { payload }) => {
        state.goals = payload.data;
      })
      .addCase(updateList.rejected, (state, { payload }) => {
        state.errorMsg = payload;
      })

      .addCase(update.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(update.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        let tempState = [...state.goals];

        if (payload.response.data.yearMonths.includes(payload.yearMonth)) {
          const elementIndex = tempState.findIndex((item) => item.id === payload.id);
          const data = { ...tempState[elementIndex], ...payload.response.data };
          tempState.splice(elementIndex, 1, data);
        } else {
          tempState = tempState.filter((i) => i.id !== payload.id);
        }

        state.goals = tempState;
      })
      .addCase(update.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(deleteGoal.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(deleteGoal.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.goals = [...state.goals].filter((item) => item.id !== payload.id);
      })
      .addCase(deleteGoal.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(moveDate.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(moveDate.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.goals = [...state.goals].filter((item) => item.id !== payload.id);
      })
      .addCase(moveDate.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(continueGoal.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(continueGoal.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.goals = state.goals.map((goal) => {
          if (goal.id === payload.id) return { ...goal, yearMonths: [...goal.yearMonths, payload.yearMonth] };
          return goal;
        }) as IGoal[];
      })
      .addCase(continueGoal.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })

      .addCase(updateStatus.pending, (state) => {
        state.errorMsg = null;
      })
      .addCase(updateStatus.fulfilled, (state, { payload }) => {
        state.goals = [...state.goals].map((item) => {
          if (item.id === payload.id) {
            item.status = payload.status;

            item.yearMonths = [...item.yearMonths].filter((month) => dayjs(payload.month).isSameOrAfter(month, "month"));
          }
          return item;
        });
      })
      .addCase(updateStatus.rejected, (state, { payload }) => {
        state.errorMsg = payload;
      });
  },
});

/* eslint-enable */
export const { setCurrentId } = businessGoals.actions;
export default businessGoals.reducer;
