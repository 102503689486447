import React, { FC } from "react";

import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as SvgSuccess } from "@assets/success_task.svg";
import { HeaderModal, ModalUI, TextFont } from "@ui";

interface IProps {
  onClose: () => void;
  isVisible: boolean;
}

export const ZoomSuccess: FC<IProps> = ({ onClose, isVisible }) => {
  const [, setParams] = useSearchParams();

  const handleClose = () => {
    setParams(undefined);
    onClose();
  };

  return (
    <ModalUI isVisible={isVisible} onClose={handleClose}>
      <HeaderModal title="Zoom" leftSide={{ onPressClose: handleClose }} />
      <Container>
        <SvgSuccess width={48} height={48} />
        <TextFont>Вы успешно авторизовались в Zoom</TextFont>
      </Container>
    </ModalUI>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 80%;
`;
