import { createSlice } from "@reduxjs/toolkit";

import { resetStore } from "@constants/logic";
import { IExternalUser } from "@services/meet.service";

import { createExternalUser, getExternalUsers } from "./api";

interface IInitState {
  isLoading: boolean;
  errorMsg: null | string;
  externalUsers: IExternalUser[];
  searchedExternalUsers: IExternalUser[];
}

const initialState: IInitState = {
  isLoading: false,
  errorMsg: null,
  externalUsers: [],
  searchedExternalUsers: [],
};

const externalUsersSlice = createSlice({
  name: "externalUsersSlice",
  initialState,
  reducers: {
    removeExternalUser: (state, { payload }) => {
      state.externalUsers = state.externalUsers.filter((user) => user.id !== payload);
    },
    resetExternalUsers: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(createExternalUser.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(createExternalUser.fulfilled, (state, { payload }) => {
        const data = { ...payload };
        state.isLoading = false;

        data.isExternalUser = true;
        state.externalUsers = [...state.externalUsers, data];
      })
      .addCase(createExternalUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      })
      .addCase(getExternalUsers.pending, (state) => {
        state.isLoading = true;
        state.errorMsg = null;
      })
      .addCase(getExternalUsers.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.searchedExternalUsers = payload;
      })
      .addCase(getExternalUsers.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.errorMsg = payload;
      });
  },
});

export const { removeExternalUser, resetExternalUsers } = externalUsersSlice.actions;
export default externalUsersSlice.reducer;
