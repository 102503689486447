import React, { FC, useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";

import { ReactComponent as SvgWrapperPeople } from "@assets/people_wrapper.svg";
import { LoaderRenderWithCondition } from "@hoc";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IStateUser } from "@interfaces/user.interface";
import { IExternalUser, meetService } from "@services/meet.service";
import { useGetPastMeetsListQuery } from "@services/userApi";
import { commonStyles } from "@styles/styles";
import { Colors } from "@theme/colors";
import { HeaderModal, ModalUI, SearchInput, TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import { toFormatDateTimeRu } from "@utils/toFormatTime";

import { renderResult } from "../../viewNotSave/components/repeat/utils";

const eventHelperUtils = new EventHelperUtils();

interface IProps {
  onClose: () => void;
  onPress: (users: IStateUser | IExternalUser | (IMeetParticipants | IExternalUser)[], isExternalUser?: boolean) => void;
}

export const MeetsPastList: FC<IProps> = ({ onClose, onPress }) => {
  const [search, setSearch] = useState("");
  const [meets, setMeets] = useState<IMeet[]>([]);
  const [params, setParams] = useState<{ searchQuery: string }>({ searchQuery: "" });

  const { data: response, isFetching } = useGetPastMeetsListQuery(params);

  useEffect(() => {
    eventHelperUtils.debounce(
      async () => {
        setParams({ searchQuery: search });
      },
      search ? 1000 : 0,
    );
  }, [response?.length, search]);

  useEffect(() => {
    setMeets(response ?? []);
  }, [response]);

  const handlePress = useCallback(async (meet: IMeet) => {
    onClose();

    const res = await meetService.getParticipantsAll(meet.id, {
      startTime: meet.startTime as string,
      endTime: meet.endTime as string,
    });

    const participants = [...res.data].filter((i) => !!i.user).map((i) => i.user);
    const tempUsersWithOutFired = [...participants].filter((user) => user.active);
    const tempUsersWithOutDeleted = [...meet.externalUsers].filter((user) => !user.deleted);
    onPress([...tempUsersWithOutFired, ...tempUsersWithOutDeleted]);
  }, []);

  return (
    <ModalUI isVisible scrollEnable={false} onClose={onClose}>
      <HeaderModal title="listOfMeetings" leftSide={{ onPressClose: onClose }} />

      <div style={styles.container}>
        <div style={styles.searchWrapper}>
          <SearchInput value={search} setValue={setSearch} placeholder="Введите название встречи" isDrop />
        </div>

        <LoaderRenderWithCondition condition={isFetching}>
          <div style={{ height: "83%", overflowY: "auto" }}>
            {meets.map((i) => (
              <button key={i.id} onClick={() => handlePress(i)} style={styles.item}>
                <SvgWrapperPeople />
                <div style={{ maxWidth: "80%" }}>
                  <TextFont weight="700" size={16} type="bold" style={commonStyles.oneOfLines}>
                    {i.name}
                    {i.description ? ` - ${i.description}` : ""}
                  </TextFont>
                  <TextFont size={12} color={Colors.LIGHT.placeholder.main}>
                    {i.repeat
                      ? renderResult({
                          type: i.repeat.type,
                          endTime: i.repeat.endTime,
                          days: i.repeat.dayOfWeeks,
                          daysRepeat: i.repeat.days,
                          period: i.repeat.repetitionPeriod,
                        })
                      : toFormatDateTimeRu(dayjs(i.startTime))}
                  </TextFont>
                </div>
              </button>
            ))}
          </div>
        </LoaderRenderWithCondition>
      </div>
    </ModalUI>
  );
};

const styles = {
  container: {
    paddingTop: 20,
    height: "100%",
  },
  item: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: 14,
    padding: "8px 10px",
    backgroundColor: "white",
    borderBottom: `1px solid ${Colors.LIGHT.lighGrey}`,
  },
  searchWrapper: {
    paddingLeft: "12px",
    paddingRight: "12px",
    marginBottom: 20,
  },
};
