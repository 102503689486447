import React, { FC, useEffect, useMemo, useRef, useState, MouseEvent } from "react";

import { isArray } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as SvgAddPeople } from "@assets/people_add.svg";
import { ReactComponent as SvgSearch } from "@assets/search.svg";
import { Groups } from "@components/screens/profile/components";
import { NotificationConfirm } from "@components/ui/notification/NotificationConfirm";
import { LoaderRenderWithCondition, ModalRenderWithCondition, RenderWithCondition } from "@hoc";
import { IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import { IMeet, IMeetParticipants } from "@interfaces/meet.interface";
import { IGetUserParams, IStateUser } from "@interfaces/user.interface";
import { InviteExternalParticipantBottomSheet } from "@screens/day/components/timetable/main/modal/components/participants/components";
import { AddNonExistingParticipantButton } from "@screens/day/components/timetable/main/modal/components/participants/components/AddNonExistingParticipantButton";
import { IExternalUser } from "@services/meet.service";
import { useDeleteExternalParticipantMutation } from "@services/meetApi";
import { selectExternalUsers } from "@store/externalUsers";
import { Colors } from "@theme/colors";
import { ArrowSvg, Loader, ModalUI, TextFont } from "@ui";
import EventHelperUtils from "@utils/event-helper.utills";
import { isInnerMeetParticipant, isStateUser } from "@utils/userTypeChecks";

import { useHandleParticipantSearch } from "../../../../hooks/useHandleParticipantSearch";
import { Item } from "../../../participants/participantsList/Item";
import { MeetsPastList } from "../../../participants/participantsList/MeetsPastList";
import RemoveParticipantsButton from "../../../participants/participantsList/RemoveParticipantsButton";
import { ICommonFields } from "../../../viewNotSave/types";
import { Participants } from "../Participants";

const eventHelperUtils = new EventHelperUtils();
const next = 16;

interface IProps extends ICommonFields {
  data: IMeet;
  setParticipantLight: (arg: string) => void;
  participantLight: string;
  isUserHasEditPermissions: boolean;
  participantsAttendance: IParticipantAttendance[] | null;
  deletedExternalUsersIds?: string[];
  setDeletedExternalUsersIds?: React.Dispatch<React.SetStateAction<string[]>>; // (ids: string[]) => void;
}

export const TabletParticpants: FC<IProps> = ({
  data,
  setParticipantLight,
  participantLight,
  isUserHasEditPermissions,
  handleData,
  participantsAttendance,
  deletedExternalUsersIds,
  setDeletedExternalUsersIds,
}) => {
  const {
    search,
    listData,
    isLoading,
    isFetching,
    isInviteExternalParticipantBtnVisible,
    isInviteExternalParticipantModalVisible,
    searchedExternalUsersResponse,
    setSearch,
    setCurrent,
    handleResetSearch,
    loadMoreData,
    setGetUsersParams,
    setIsInviteExternalParticipantModalVisible,
    setSearchedExternalUsers,
    setSelectedUsers,
  } = useHandleParticipantSearch({ meet: data });

  const listInnerRef = useRef();
  const [isWriting, setIsWriting] = useState(false);
  const [isListPastMeetsVisible, setIsListPastMeetsVisible] = useState(false);
  const [isGroupVisible, setIsGroupVisible] = useState(false);

  const [pos, setPos] = useState({ left: 0, top: 0 });
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [externalUserIdToDelete, setExternalUserIdToDelete] = useState("");

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const { externalUsers } = useSelector(selectExternalUsers);
  const phSearch = useIntl().formatMessage({ id: "nameOrEmail" });
  const deletableUserIds = useMemo(() => new Set<string>(), []);

  const [deleteExternalParticipant] = useDeleteExternalParticipantMutation();

  const handleScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) - 10 === scrollHeight - 10) {
        return await loadMoreData();
      }
    }
  };

  const handleAddUser = (users: IStateUser | IExternalUser | (IMeetParticipants | IExternalUser)[], isExternalUser?: boolean) => {
    if (isArray(users)) {
      const externalUsers = [];
      const innerUsers: IMeetParticipants[] = [];

      for (const user of users) {
        const isInner = isInnerMeetParticipant(user);
        const userId = isInner ? user?.user?.id : user.id;
        const isIncludeParticipant = data.participants?.some((i) => i.id === userId || i.userId === userId);
        const isIncludeExternal = data.externalUsers?.some((i) => i.id === userId || i.userId === userId);

        deletableUserIds.add(userId);

        if (!isIncludeParticipant && !isIncludeExternal) {
          if (isInner) {
            innerUsers.push({
              user: { ...user, workPosition: user?.workPosition ?? null },
              userId,
              isExternalUser: false,
              meetingStatus: null,
              status: user?.status ?? null,
              id: userId,
              statusComment: null,
              statusCommentIsViewed: false,
              permissions: [],
            });
          } else {
            externalUsers.push(user);
          }
        }
      }

      handleData(
        [
          ["participants", [...(data.participants ?? []), ...innerUsers]],
          ["externalUsers", [...(data.externalUsers ?? []), ...externalUsers]],
        ],
        null,
      );
    } else {
      if (!users) return;

      const propToUpdate = isExternalUser ? "externalUsers" : "participants";
      const dataArr = (isExternalUser ? data?.externalUsers : data?.participants) ?? [];
      const updation = dataArr.filter((item) => item.id !== users.id);
      const isAddedPreviously = dataArr.some((item) => item.id === users.id || item.userId === users.id);

      if (isAddedPreviously) {
        const updatedData = (() => {
          if (isExternalUser) {
            return data.externalUsers?.filter((user) => user.userId !== users.id && user.id !== users.id);
          }

          return data.participants?.filter((user) => user.userId !== users.id);
        })();

        handleData(propToUpdate, updatedData);

        deletableUserIds.delete(users.id);
      } else {
        const innerUserTemplate = {
          userId: users.id,
          status: null,
          user: users,
          meetingStatus: null,
          statusComment: null,
          statusCommentIsViewed: null,
          permissions: [],
        } as unknown as IMeetParticipants;

        const userToAdd = isExternalUser ? ({ ...users } as IExternalUser) : innerUserTemplate;
        updation.push(userToAdd);
        deletableUserIds.add(users.id);

        handleData(propToUpdate, updation);
      }
    }
  };

  useEffect(() => {
    if (search) {
      setIsWriting(true);
    }

    const query = search ? { query: search } : {};
    const additionalParams = search ? {} : { showFromDelegationChain: true, showFromDepartment: false, showOthers: false };
    const params: IGetUserParams = {
      id: data?.id ?? "",
      params: { ...query, current: 0, next, startTime: data?.startTime ?? "", endTime: data?.endTime, ...additionalParams },
    };

    if (!search) setGetUsersParams(params);

    eventHelperUtils.debounce(
      async () => {
        setGetUsersParams(params);
        setCurrent(next);
        setIsWriting(false);
      },
      search ? 1000 : 0,
    );
  }, [search, searchedExternalUsersResponse]);

  useEffect(() => {
    document.getElementById("modalActivityParticipant").addEventListener("click", (e) => {
      if (!document.getElementById("listParticipant")?.contains(e?.target)) {
        setIsSearchVisible(false);
      }
    });
  }, []);

  useEffect(() => {
    const handleCloseModal = () => {
      if (isOpenContextMenu) {
        setIsOpenContextMenu(false);
      }
    };
    document.addEventListener("click", handleCloseModal);
    return () => document.removeEventListener("click", handleCloseModal);
  }, [isOpenContextMenu]);

  const handleRightClick = async (e: MouseEvent<HTMLButtonElement>, item: IStateUser | IExternalUser) => {
    e.preventDefault();
    setExternalUserIdToDelete(item.id);
    setIsOpenContextMenu(true);
    setPos({ top: e.clientY, left: e.clientX });
  };

  const removeExternalParticipant = (externalUserId: string) => {
    deleteExternalParticipant({ externalUserId, meetId: data.id, isMeet: true, disableUpdate: true }).then(() => {
      setSearchedExternalUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setSelectedUsers((prev) => prev.filter((el) => el.id !== externalUserId));
      setDeletedExternalUsersIds((prev) => [...prev, externalUserId]);
    });
  };

  const renderSearch = (
    <FilterContainer>
      <SearchInputContainer>
        <SvgSearch />
        <Input onFocus={() => setIsSearchVisible(true)} value={search} onChange={(e) => setSearch(e.target.value)} placeholder={phSearch} />
      </SearchInputContainer>
      <RenderWithCondition condition={!!search}>
        <button onClick={handleResetSearch}>
          <TextFont style={textDrop}>
            <FormattedMessage id={"drop"} />
          </TextFont>
        </button>
      </RenderWithCondition>
    </FilterContainer>
  );

  return (
    <div id="listParticipant" style={{ position: "relative" }}>
      <Participants
        search={renderSearch}
        participantLight={participantLight}
        data={data}
        setParticipantLight={setParticipantLight}
        handleData={handleData}
        isUserHasEditPermissions={isUserHasEditPermissions}
        participantsAttendance={participantsAttendance}
        deletableUserIds={deletableUserIds}
        deletedExternalUsersIds={deletedExternalUsersIds}
      />

      <ModalRenderWithCondition condition={isSearchVisible}>
        <ModalContentWrapper>
          {!search && (
            <SelectFromPastParticipantButton onClick={() => setIsListPastMeetsVisible(true)}>
              <AddParticipantWrapper>
                <SvgAddPeople width={42} height={42} />
                <TextFont size={16} weight="700" type="bold">
                  <FormattedMessage id="selectParticipantsFromMeetings" />
                </TextFont>
              </AddParticipantWrapper>
              <ArrowSvg type="right" />
            </SelectFromPastParticipantButton>
          )}

          {!search && (
            <SelectFromPastParticipantButton onClick={() => setIsGroupVisible(true)}>
              <AddParticipantWrapper>
                <SvgAddPeople fill={Colors.LIGHT.blue} width={42} height={42} />
                <TextFont size={16} weight="700" type="bold">
                  <FormattedMessage id="selectGroup" />
                </TextFont>
              </AddParticipantWrapper>
              <ArrowSvg type="right" />
            </SelectFromPastParticipantButton>
          )}
          <LoaderRenderWithCondition condition={isLoading}>
            <WrapItems ref={listInnerRef} onScroll={handleScroll}>
              <RenderWithCondition condition={isInviteExternalParticipantBtnVisible}>
                <AddNonExistingParticipantButton
                  email={search}
                  setIsInviteExternalParticipantModalVisible={setIsInviteExternalParticipantModalVisible}
                />
              </RenderWithCondition>
              <RenderWithCondition condition={!listData.length && !isFetching && !isWriting}>
                <p>Не найдено</p>
              </RenderWithCondition>
              {listData.map((item) => (
                <>
                  <Item
                    isDeletable={deletableUserIds?.has(item.id)}
                    isAdded={
                      data.participants?.some((i) => i.userId === item.id) ||
                      data?.externalUsers?.some((i) => i.id === item.id) ||
                      externalUsers?.some((i) => i.id === item.id)
                    }
                    key={item.id}
                    item={item}
                    onSave={handleAddUser}
                    onRightClick={handleRightClick}
                    hover="#e3e9e7ac"
                    isUserHasEditPermissions={isUserHasEditPermissions}
                  />
                  {(item as IExternalUser).isExternalUser && !(item as IExternalUser).deleted && (
                    <RemoveParticipantsButton
                      externalUserId={item.id}
                      isVisible={isOpenContextMenu && item.id === externalUserIdToDelete}
                      position={{ left: pos.left, top: pos.top }}
                      onClick={() => {
                        setExternalUserIdToDelete(item.id);
                        setIsConfirmModalOpen(true);
                      }}
                    />
                  )}
                </>
              ))}
              <RenderWithCondition condition={isFetching || isWriting}>
                <Loader posY="98%" />
              </RenderWithCondition>
            </WrapItems>
          </LoaderRenderWithCondition>
        </ModalContentWrapper>
      </ModalRenderWithCondition>

      <RenderWithCondition condition={isInviteExternalParticipantModalVisible}>
        <InviteExternalParticipantBottomSheet
          participantEmail={search}
          isVisible={isInviteExternalParticipantModalVisible}
          setIsVisible={setIsInviteExternalParticipantModalVisible}
          closeParticipant={() => {
            setIsInviteExternalParticipantModalVisible(false);
          }}
          setSearch={setSearch}
        />
      </RenderWithCondition>

      <ModalRenderWithCondition condition={isListPastMeetsVisible}>
        <MeetsPastList onClose={() => setIsListPastMeetsVisible(false)} onPress={handleAddUser} />
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isGroupVisible}>
        <ModalUI isVisible={isGroupVisible} onClose={() => setIsGroupVisible(false)}>
          <Groups onClose={() => setIsGroupVisible(false)} forParticipants={true} onSave={handleAddUser} />
        </ModalUI>
      </ModalRenderWithCondition>

      <ModalRenderWithCondition condition={isConfirmModalOpen}>
        <NotificationConfirm
          isOpen={true}
          phraseId="externalUserWillDelete"
          phraseOkId="delete"
          phraseCancelId="cancel"
          onOk={() => {
            removeExternalParticipant(externalUserIdToDelete);
            setIsConfirmModalOpen(false);
          }}
          onCancel={() => {
            setIsConfirmModalOpen(false);
          }}
        />
      </ModalRenderWithCondition>
    </div>
  );
};

const ModalContentWrapper = styled.div`
  top: 100px;
  width: 100%;
  z-index: 999;
  padding: 5px 0;
  position: absolute;
`;

const WrapItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  min-height: 250px;
  max-height: 400px;
  background-color: rgb(245, 249, 248);
`;

const SearchInputContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 38px;
  border-radius: 10px;
  background-color: #ecf0ef;
  padding: 8px;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  position: relative;
`;

const SelectFromPastParticipantButton = styled.button`
  display: flex;
  width: 100%;
  padding: 8px 10px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid ${Colors.LIGHT.lighGrey};
`;

const AddParticipantWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Input = styled.input`
  margin-left: 6px;
  width: 100%;
`;

const textDrop = {
  fontSize: 16,
  lineHeight: "24px",
  color: "#297952",
  marginLeft: 15,
};
