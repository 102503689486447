import React from "react";

import { Route, BrowserRouter } from "react-router-dom";

import { Auth, CheckCode, Registration, Mounth, Day, Analysis, Task, Profile } from "../screens";

import ExtendedRoutes from "./components/ExtendedRoutes";

export const AppRouter = () => (
  <BrowserRouter>
    <ExtendedRoutes authPaths={["/auth", "/checkCode"]} publicPaths={["/auth", "/checkCode", "/registration"]}>
      <Route path="/auth" element={<Auth />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/checkCode" element={<CheckCode />} />
      <Route path="/month" element={<Mounth />} />
      <Route path="/profile">
        <Route index element={<Profile />} />
        <Route path="zoom-success" element={<Profile />} />
      </Route>
      <Route path="/">
        <Route index element={<Day />} />
        <Route path="analysis" element={<Analysis />} />
      </Route>
      <Route path="/tasks" element={<Task />} />
    </ExtendedRoutes>
  </BrowserRouter>
);
